<template>
    <div class="flex flex-col w-full justify-start">
        <div class="flex flex-col mt-6 w-full">
            <ContactAutocompleteVue
            class="mb-4 w-full"
            :height="'medium'"
            v-model="filterParameters.contact"
            @update:modelValue="$emit('update:modelValue', filterParameters)"/>
            <CheckboxVue 
            :label="'Contact destinataire'"
            :height="'medium'" 
            v-model="filterParameters.contactConsignee" 
            @update:modelValue="$emit('update:modelValue', filterParameters)"/>
            <CheckboxVue 
            :label="'Contact expéditeur'"
            :height="'medium'" 
            v-model="filterParameters.contactShipper" 
            @update:modelValue="$emit('update:modelValue', filterParameters)"/>
        </div>
        <div class="flex flex-row w-full justify-start items-end mt-6">
            <TagVue 
            :size="'small'"
            :alert="null"
            :type="'traqr'"
            :state="'awaiting'"
            />
            <CheckboxVue 
            :label="'En attente'" 
            :height="'medium'" 
            v-model="filterParameters.awaiting" 
            @update:modelValue="$emit('update:modelValue', filterParameters)"/>
        </div>
        <div class="flex flex-row w-full justify-start items-end">
            <TagVue 
            :size="'small'"
            :alert="null"
            :type="'traqr'"
            :state="'transit'"
            />
            <CheckboxVue 
            :label="'En transit'"
            :height="'medium'" 
            v-model="filterParameters.transit" 
            @update:modelValue="$emit('update:modelValue', filterParameters)"/>
        </div>
        <div class="flex flex-row w-full justify-start items-end">
            <TagVue 
            :size="'small'"
            :alert="null"
            :type="'traqr'"
            :state="'delivered'"
            />
            <CheckboxVue 
            :label="'Livré'" 
            :height="'medium'" 
            v-model="filterParameters.delivered" 
            @update:modelValue="$emit('update:modelValue', filterParameters)"
            />
        </div>  
        <div class="flex flex-col mt-6 w-full">
            <SelectVue 
            :options="optionsDate"
            :placeholder="'Type de date ...'" 
            :height="'medium'"
            v-model="filterParameters.dateType" 
            @update:modelValue="$emit('update:modelValue', filterParameters)"
            />
            <SelectVue 
            :options="optionsDateOrder" 
            :placeholder="'Ordre des date ...'"
            :height="'medium'"
            v-model="filterParameters.dateOrder" 
            @update:modelValue="$emit('update:modelValue', filterParameters)" 
            class="mt-2"
            />
            <DatefieldVue
            class="mt-2"
            v-model="filterParameters.dateAfter"
            :placeholder="filterParameters.dateType < 2 ? 'Mis à jour après' : 'Créé après'"
            :height="'medium'"
            :state="'default'"
            :top="false"
            @update:modelValue="$emit('update:modelValue', filterParameters)"
            />
            <DatefieldVue
            class="mt-2"
            :placeholder="filterParameters.dateType < 2 ? 'Mis à jour avant' : 'Créé avant'"
            :height="'medium'"
            v-model="filterParameters.dateBefore"
            @update:modelValue="$emit('update:modelValue', filterParameters)"
            />
        </div>
        <SelectVue 
        :options="options" 
        :placeholder="'Type d\'adresse ...'"
        :height="'medium'"
        v-model="filterParameters.addressType" 
        @update:modelValue="$emit('update:modelValue', filterParameters)" 
        class="mt-6"
        />
        <InputVue class="mt-2"
        :placeholder="'Adresse'"
        v-model="filterParameters.address"
        @update:modelValue="$emit('update:modelValue', filterParameters)"
        :height="'medium'"
        />
        <InputVue class="mt-2 w-full"
        :placeholder="'Code Postal'"
        v-model="filterParameters.zipcode"
        @update:modelValue="$emit('update:modelValue', filterParameters)"
        :height="'medium'"
        />
        <InputVue class="mt-2 w-full"
        :placeholder="'Ville'"
        v-model="filterParameters.city"
        @update:modelValue="$emit('update:modelValue', filterParameters)"
        :height="'medium'"
        />
        <InputVue class="mt-2 w-full"
        :placeholder="'Pays'"
        v-model="filterParameters.country"
        @update:modelValue="$emit('update:modelValue', filterParameters)"
        :height="'medium'"
        />
    </div>
</template>

<script>
import TagVue from "@/components/elements/Tag.vue"
import CheckboxVue from "@/components/elements/Checkbox.vue"
import SelectVue from "@/components/elements/Select.vue"
import InputVue from "@/components/elements/Input.vue"
import DatefieldVue from "@/components/elements/Datefield.vue"
import ContactAutocompleteVue from "@/components/groups/ContactAutocomplete.vue"

export default ({
    name: 'FiltersSection',
    components: {
        TagVue,
        CheckboxVue,
        SelectVue,
        InputVue,
        DatefieldVue,
        ContactAutocompleteVue
    },
    props:{
        optionsSelected:{
            type: Number
        },
        optionsReferenceSelected:{
            type: Number
        },
        modelValue:{
            type: Object
        }
    },
    data(){
        return {
            filterParameters: {
                contact: null,
                contactConsignee: true,
                contactShipper: true,
                awaiting: null,
                transit: null,
                delivered: null,
                addressType: 0,
                address: null,
                zipcode: null,
                city: null,
                country: null,
                dateType: null,
                dateOrder: null,
                dateAfter: null,
                dateBefore: null,
            },
            options: [
                {
                    name : "Adresse de livraison"
                },
                {
                    name : "Adresse d'expédition"
                },
            ],
            optionsDate: [
                {
                    name : "Mise à jour" 
                },
                {
                    name : "Création"
                },
            ],
            optionsDateOrder: [
                {
                    name : "Décroissant"
                },
                {
                    name : "Croissant"
                },
            ],
        }
    },
})
</script>

