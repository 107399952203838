<template>
    <AutocompleteVue :key="reload"
    v-model="rawDestination"
    :placeholder="'Rechercher une adresse...'"
    :height="'large'"
    :objects="destinations"
    :state="setState"
    @input="searchDestination()"
    @setValue="setDestination($event)"
    />
    <div class="flex flex-row justify-between items-center my-2">
        <AutocompleteVue :key="reload"
        class="w-full mr-2"
        v-model="rawContact"
        :placeholder="'Rechercher un contact...'"
        :height="'large'"
        :objects="contacts"
        :state="destination.id ? 'default' : 'disabled'"
        @input="searchContact()"
        @setValue="setContact($event)"
        />
        <ButtonVue 
        @click="openContactSheet = true"
        :filling="'filled'"
        :label="null"
        :height="'large'"
        :icon="'PlusIcon'"
        :state="'success'"
        />
    </div>
    <RightSheetVue v-if="openContactSheet"
    @closeModal="openContactSheet = false">
        <template v-slot:body>
            <ContactFormVue
            @contact="$event => {
                newContact($event)
            }"/>
        </template>
    </RightSheetVue>
</template>
    
    
    
<script>
import Destination from '@/lib/entities/Destination'
import Contact from '@/lib/entities/Contact'
import AutocompleteVue from '@/components/elements/Autocomplete.vue'
import ButtonVue from '@/components/elements/Button.vue'
import RightSheetVue from '@/components/containers/RightSheet.vue'
import ContactFormVue from '@/components/groups/ContactForm.vue'


export default {
    name: 'DestinationAutocomplete',
    components:{
        AutocompleteVue,
        ButtonVue,
        RightSheetVue,
        ContactFormVue,
    },
    data(){
        return {
            destination: new Destination(),
            destinationLoadingConstraints: '',
            contact: new Contact(),
            destinations: [],
            contacts: [],
            setState: 'warning',
            rawDestination: '',
            rawContact: '',
            openContactSheet: false,
            reload: 0
        }
    },
    watch: {
        modelValue: {
          handler(){
            this.destination = this.modelValue 
            this.rawDestination = this.destination.address ? this.destination.address.display() : null
            this.setState = 'success'
            this.rawContact = this.destination.contact ? this.destination.contact.display() : null
            this.destination.loadingConstraintsComment = this.destinationLoadingConstraints
            this.$emit('update:modelValue', this.destination)
          }
        }
    },
    props:{
        modelValue: {
          type: Object,
        }
      },
    methods: {
        async searchDestination(){
            this.destinations = await this.destination.searchDestination(this.rawDestination)
        },
        async setDestination(event){
            await this.destination.setDestination(event)
            .then((response) => {
                this.rawDestination = response.address.display()
                this.setState = 'success'
                this.rawContact = this.destination.contact ? this.destination.contact.display() : null
                this.destination.loadingConstraintsComment = this.destinationLoadingConstraints
                this.$emit('update:modelValue', this.destination)
            })
            this.destinations = []
        },

        async searchContact(){
            this.contacts = await this.contact.searchContact(this.rawContact)
            this.contacts.forEach((contact, index) => {
                this.contacts[index] = new Contact(contact)
                this.contacts[index].description = this.contacts[index].display() 
            })
        },
        async setContact(event){
            this.destination.contact = event
            this.rawContact = this.destination.contact.display()
            this.contacts = []
            this.destination.update()
            this.destination.loadingConstraintsComment = this.destinationLoadingConstraints
            this.$emit('update:modelValue', this.destination)
        },

        newContact(object){
            this.destination.contact = new Contact(object)
            this.destination.update()
            this.destination.loadingConstraintsComment = this.destinationLoadingConstraints
            this.$emit('update:modelValue', this.destination)
            this.rawContact = this.destination.contact.display() 
        }
    
    }
}
    </script>
    