<template>
    <nav class="fixed z-50 top-0 flex items-center justify-between flex-no-wrap bg-gradient-to-r from-neutral-700 to-primary-500 w-full"
    :class="!isMobile() ?  'h-[100px]' : 'h-[70px]'"
    >
      <router-link :to="'/traqrs_read'">
        <div class="flex flex-row">
            <img :src="TraqKingLogo" :width="!isMobile() ?  '100' : '70'"/>
            <img :src="TraqKingDark" :width="!isMobile() ?  '120' : '100'"/>
        </div>
      </router-link>
        <div class="flex flex-row">
            <div class="h-0 w-0 border-t-warning-500 border-l-transparent"
            :class="!isMobile() ?  'border-t-[100px] border-l-[100px]' : 'border-t-[70px] border-l-[70px]'"
            ></div>
            <div class="px-5 bg-warning-500 flex flex-row items-center justify-end">
                <ul v-if="routes && !isMobile()" class="flex flex-row items-center">
                    <li v-for="(value, index) in routes" :key="index" class="mr-1">
                        <router-link :to="value.route[0]">
                        <a v-if="tab != index" class="bg-transparent inline-block py-2 px-4 text-gray-500 hover:text-gray-200 font-semibold" href="#"> {{ value.title }}</a>
                        <a v-else class="bg-transparent inline-block border-b-2 border-white py-2 px-4 text-white font-semibold" href="#">{{ value.title }}</a>
                        </router-link>
                    </li>
                    <li v-if="routes.length == 3" :key="4" class="mr-1">
                      <ButtonVue
                      class="!m-4"
                      :label="null"
                      :filling="'filled'"
                      :height="'large'"
                      :state="'danger'"
                      :icon="'ArrowRightOnRectangleIcon'"
                      @click="disconnect()"
                      />
                    </li>
                </ul>
                <div v-else>
                    <component :is="heroIcons['Bars3Icon']" fill="none" class="stroke-1 text-white h-10 w-10 mx-5" @click="menu = true"/>
                    <transition name="fade" appear>
                        <div v-if="menu" class="w-full h-[100vh] absolute z-20 top-0 left-0 overlay" @click="menu = false"></div>
                    </transition>
                    <transition name="slide" appear>
                        <div v-if="menu" class="w-50% h-[100vh] absolute z-30 top-0 right-0 bg-gray-50">
                            <div class="absolute bg-gray-50 text-gray-500 top-[1rem] w-[2rem] h-[5rem] left-[calc(-2rem)] rounded-l-button flex items-center justify-center" @click="menu = false">
                                <component :is="heroIcons['ChevronRightIcon']" fill="none" class="stroke-1 h-7 w-7" />
                            </div>
                            <div class="flex flex-col h-full py-4 justify-between">
                              <ul class="flex flex-col mt-10 mr-5 items-end justify-end w-full" v-if="routes">
                                  <li v-for="(value, index) in routes" :key="index" class="my-5" @click="menu = false">
                                      <router-link :to="value.route[0]">
                                          <a v-if="tab === index" class="bg-transparent inline-block border-b-2 border-primary-700 py-2 px-4 text-primary-700 font-semibold" href="#">{{ value.title }}</a>
                                          <a v-else class="bg-transparent inline-block py-2 px-4 text-neutral-700 hover:text-neutral-200 font-semibold" href="#"> {{ value.title }}</a>
                                      </router-link>
                                  </li>
                              </ul>
                              <ButtonVue
                              class="!m-4 w-full"
                              :label="'Se deconnecter'"
                              :filling="'filled'"
                              :height="'large'"
                              :state="'danger'"
                              :icon="'ArrowRightOnRectangleIcon'"
                              @click="disconnect()"
                              />
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>

import LoadingVue from '@/components/elements/Loading.vue'
import ButtonVue from '@/components/elements/Button.vue'
import * as heroIcons from '@heroicons/vue/24/outline'


export default ({
  name: 'NavBar',
  components: {
    LoadingVue,
    ButtonVue
  },
  data(){
    return {
      heroIcons: heroIcons,
      TraqKingLogo: require('../../../assets/traq-king-logo.svg'),
      TraqKingDark: require('../../../assets/traq-king-dark.svg'),
      tab: null,
      routes: null,
      menu: false,
    }
  },
  async mounted(){
    this.setTab()
  },
  props:{
      firstName:{
          type: String,
          default: null
      },
  },
  methods: {
    isMobile() {
      return (this.$device === 'mobile' ||  this.$orientation === 'portrait') && window.innerWidth < 768
    },
    setTab(){
      this.routes = [
        {
          title: 'Créer TraQR',
          route: ['/traqr_create']
        },
        {
          title: 'Suivre TraQRs',
          route: ['/traqrs_read']
        },
        {
          title: 'Contacts',
          route: ['/contacts']
        },
        {
          title: 'Compte',
          route: ['/account']
        },
      ]
      this.tab = this.routes.findIndex(element => element.route.findIndex(elmt => elmt === this.$router.currentRoute.value.fullPath) >= 0)
      if(this.$me && this.$me.firstName && this.$me.roles.includes("ROLE_COMPANY_ADMIN")){
        this.routes[this.routes.length - 1].title = this.firstName
        return
      } else {
        this.routes.pop();
      }
    },
    async disconnect(){
            await this.$axios
            .post(process.env.VUE_APP_API_BASE_PATH+'/logout', 
            {}, { withCredentials: true })
            .then(response => {
                if(response.status == 200){
                    window.location.replace("https://www.traq-king.com");
                }
            })
        },
  },
  watch:{
    '$router.currentRoute.value.fullPath':{
      handler(){
        this.setTab()
      },
      deep: true,
      immediate: true
    },
  }

})

</script>

<style scoped>

.slide-enter-from,
.slide-leave-to {
    transform: translate(100%, 0);
}

.slide-enter-active,
.slide-leave-active {
    transition: all .3s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: all .3s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.overlay {
    background: #9ca3af;
    opacity: 0.5;
}

</style>
