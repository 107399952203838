<template>
    <div class="flex items-center py-2 px-4 justify-between bg-gray-100 border-b"
    :class="!isMobile() ? 'flex-row' : 'flex-col-reverse'"
    >
        <div class="flex items-center justify-center "
        :class="!isMobile() ? 'w-50%' : 'w-full'">
            <InputVue
            class="w-full mr-2"
            :placeholder="'Référence'"
            v-model="dataReference"
            @update:modelValue="$emit('update:modelValue', dataReference)"
            :height="!isMobile() ? 'large' : 'medium'"
            :state="'default'"
            :disabled="false"
            />
            <ButtonVue v-if="!isMobile()"
            :filling="'filled'"
            :label="null"
            :height="!isMobile() ? 'large' : 'medium'"
            :icon="'MagnifyingGlassIcon'"
            :state="'default'"
            :disabled="false"
            @click="$emit('updateReference')"
            />
            <ButtonVue v-else
            :filling="'filled'"
            :label="null"
            :height="!isMobile() ? 'large' : 'medium'"
            :icon="'FunnelIcon'"
            :state="'default'"
            :disabled="false"
            @click="$emit('openFilters')"
            />
        </div>
        <div :class="isMobile() ? 'mb-2 z-10 flex flex-col items-center justify-center w-full' : null">
            <PaginatorVue
            :pageNumber="pageNumber"
            :pageTotal="pageTotal"
            @nextPage="$emit('nextPage')"
            @previousPage="$emit('previousPage')"
            />
        </div>
    </div>
</template>

<script>
import InputVue from "@/components/elements/Input.vue"
import ButtonVue from "@/components/elements/Button.vue"
import PaginatorVue from "@/components/elements/Paginator.vue"

export default {
  name: 'TraqrReferenceVue',
  components: {
    InputVue,
    ButtonVue,
    PaginatorVue
  },
  props:{
    pageNumber: {
        type: Number,
        default: 1
    },
    pageTotal: {
        type: Number,
        default: 1
    },
    reference:{
        type: String,
        default: null
    },
  },
  data(){
    return {
      dataReference : this.reference
    }
  },
  methods:{
    isMobile() {
        return this.$device === 'mobile' ||  this.$orientation === 'portrait'
    },
  },
  watch: {
    pageNumber:{
        handler(){},
        deep: true
    },
    pageTotal:{
        handler(){},
        deep: true
    },
    reference:{
        handler(){},
        deep: true
    },
  },
}
</script>