<template lang="html">
    <div class="flex flex-row justify-between items-center w-full h-full relative">
            <SelectVue class="min-w-[110px] w-[20%]"
            :options="phoneCodes" 
            :placeholder="null"
            :height="'large'"
            v-model="country"
            />
            <InputVue class="w-full ml-2"
            v-model="phone"
            :placeholder="'Téléphone'"
            :height="'large'"
            :state="phoneState"
            :enableValidation="true"
            :type="'text'"
            />
    </div>
</template>


  
<script lang="js">

import InputVue from '@/components/elements/Input.vue'
import SelectVue from '@/components/elements/Select.vue'
import { isValidPhoneNumber, AsYouType, parsePhoneNumber } from 'libphonenumber-js';


import { phoneCodes } from '@/lib/PhoneCodes'


export default  {
    name: 'PhoneInput',
    components:{
        SelectVue,
        InputVue
    },
    props:{
        modelValue: {
            type: String,
            default: null
        },
    },
    data () {
        return {
            phone: null,
            formattedPhone: null,
            phoneState: "default",
            country: null,
            countryCode: null,
            phoneCodes: phoneCodes,
        }
    },
    created(){
        this.country = 8
        this.countryCode = 'FR'
        if(this.modelValue){
            var phone = parsePhoneNumber(this.modelValue)
            this.phone = phone.formatNational()
            this.countryCode = phone.country
            this.country = phoneCodes.findIndex((a) => {return a.code == this.countryCode})
        }
        if(this.validatePhoneNumber()){
            this.$emit('validated', true)
        } else {
            this.$emit('validated', false)
        }
    },
    methods:{
        log(e){
            console.log(e)
        },
        validatePhoneNumber() {
            return this.phone && isValidPhoneNumber(this.phone, this.countryCode)
        },
    },
    watch:{
        country:{
            handler(){
                if(this.country !== null){
                    this.countryCode = phoneCodes[this.country].code
                }
            }
        },
        phone:{
            handler(){
                this.phone = this.phone !== null ? new AsYouType(this.countryCode).input(this.phone) : null
                if(this.validatePhoneNumber()){
                    this.phoneState = "success"
                    this.$emit('validated', true)
                } else {
                    this.phoneState = "danger"
                    this.$emit('validated', false)
                }
                this.$emit('update:modelValue', phoneCodes[this.country].dial_code +" "+ this.phone)
            }
        },
    }
}


</script>
  