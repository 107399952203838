<template lang="html">
    <div class="flex flex-col justify-start align-top">
        <span v-if="label" class="mb-1 mx-1 text-neutral-700" 
        :class="styling($styles.input.span_label)">
            {{label}}
        </span>
            <div class="relative w-full">
                <input
                class="rounded-text-input box-border border transition-all w-full outline-none focus:ring-1 focus:ring-inset focus:ring-offset-1 shadow-sm hover:shadow-lg focus:shadow-xs"
                :type="type !== 'password' || (type === 'password' && showPassword) ? 'text' : 'password'"
                :class="type === 'password' ? styling($styles.input.password) : styling($styles.input.text)"
                v-model="dataValue" 
                :placeholder="placeholder"
                @input="validate()"
                :disabled="currentState === 'disabled' ? 'disabled' : null"
                />
                <component v-if="type === 'password'" 
                :is="showPassword ? $heroIcons['EyeSlashIcon'] : $heroIcons['EyeIcon'] " 
                class="absolute stroke-1 stroke-neutral-600" 
                :class="styling($styles.input.icon_eye)" 
                @click="showPassword = !showPassword"/>
            </div>
            <span v-if="message" 
            class="mt-1 mx-1 inline-flex items-center" 
            :class="styling($styles.input.span_message)">
                <component
                :is="getMessageIcon" 
                fill="currentColor" 
                class="mr-1" 
                :class="styling($styles.input.icon_message)"/>
                {{message}}
            </span>
            <span v-if="helper" 
            class="mt-1 mx-1 text-neutral-400" 
            :class="styling($styles.input.span_helper)">
                {{helper}}
            </span>
        </div>
  </template>

<script>

import { isValidPhoneNumber } from 'libphonenumber-js';
  
export default  {
  name: 'InputVue',
  props: {
    modelValue: {
        type: String,
        default: ''
    },
    height: {
        type: String,
        validator(value) {
            return ['large', 'medium', 'small'].includes(value)
        }, 
        default: 'medium'
    },
    type: {
        type: String,
        validator(value) {
            return ['text', 'password', 'email', 'phone'].includes(value)
        }, 
        default: 'text'
    },
    placeholder: {
        type: String,
        default: 'Insert text here'
    },
    label: {
        type: String,
        default: ''
    },
    message: {
        type: String,
        default: ''
    },
    helper: {
        type: String,
        default: ''
    },
    enableValidation: {
        type: Boolean,
        default: false
    },
    state: {
        type: String,
        validator(value) {
            return ['default', 'success', 'danger', 'warning', 'disabled'].includes(value)
        }, 
        default: 'default'
    },
  },
  watch: {
    modelValue: {
        handler() {
            this.dataValue = this.modelValue
            this.enableValidation ? this.validate() : null
        },
        deep: true
    },
    height: {
      handler() {},
      deep: true
    },
    type: {
      handler() {},
      deep: true
    },
    placeholder: {
      handler() {},
      deep: true
    },
    label: {
      handler() {},
      deep: true
    },
    message: {
      handler() {},
      deep: true
    },
    helper: {
      handler() {},
      deep: true
    },
    state: {
        handler(newVal){
            this.currentState = newVal
        },
        deep: true
    },
  },
  data () {
    return {
      dataValue: '',
      showPassword: false,
      currentState: 'default'
    }
  },
    computed: {
        getMessageIcon() {
            return this.$heroIcons[this.$styles.input.icon_message[this.currentState]];
        },
    },
  created(){
    this.dataValue = this.modelValue
  },
  methods: {
    getState(object){
        switch (this.currentState){
            case 'default':{
                return object.default
            }
            case 'success':{
                return object.success
            }
            case 'danger':{
                return object.danger
            }
            case 'warning':{
                return object.warning
            }
            case 'disabled':{
                return object.disabled
            }
            default:{
                return object.default
            }
        }

    },
    getHeight(object){
        switch (this.height){
            case 'large':{
                return object.large
            }
            case 'medium':{
                return object.medium
            }
            case 'small':{
                return object.small
            }
            default:{
                return object.medium
            }
        }
    },
    styling(object){
        var valren = ''
        object.height ? valren += this.getHeight(object.height) +' ' : null
        object.state ? valren += this.getState(object.state) +' ' : null
        return valren
    },
    validate(){
        if(this.enableValidation){
            switch(this.type){
                case 'email':{
                    this.currentState = this.validateEmail() ? 'success' : 'danger'
                    this.validateEmail() ? this.$emit('validated', true) : this.$emit('validated', false)
                    break;
                }
                case 'phone':{
                    this.currentState = this.validatePhoneNumber() ? 'success' : 'danger'
                    this.validatePhoneNumber() ? this.$emit('validated', true) : this.$emit('validated', false)
                    break;
                }
                case 'password':{
                    this.currentState = this.validatePassword() ? 'success' : 'danger'
                    this.validatePassword() ? this.$emit('validated', true) : this.$emit('validated', false)
                    break;
                }
            }
        }
        this.$emit('update:modelValue', this.dataValue)
        this.$forceUpdate()
    },
    validateEmail() {
        return String(this.dataValue)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },

    validatePhoneNumber() {
        return isValidPhoneNumber(this.dataValue, 'FR')
    },

    validatePassword(){
        return this.containsNumber() 
        && this.containsUppercase() 
        && this.containsLowercase() 
        && this.containsSpecialChar() 
        && (this.dataValue && this.dataValue.length > 7)

    },

    containsNumber() {
        return /\d/.test(this.dataValue);
    },

    containsUppercase() {
        return /[A-Z]/.test(this.dataValue);
    },

    containsLowercase() {
        return this.dataValue ? this.dataValue.toUpperCase() != this.dataValue : false;
    },

    containsSpecialChar() {
        return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.dataValue);
    },
  },
}


</script>
  
  