<template lang="html">
    <div class="flex flex-col justify-start align-top">
        <span v-if="label" class="mb-1 mx-1 text-neutral-700" 
        :class="styling($styles.text.span_label)">
            {{label}}
        </span>
            <div class="relative w-full">
                <textarea
                class="rounded-text-input box-border border transition-all w-full outline-none focus:ring-1 focus:ring-inset focus:ring-offset-1 focus:border-primary-400 focus:ring-primary-400 shadow-sm hover:shadow-lg focus:shadow-xs"
                :class="styling($styles.text.text)"
                v-model="dataValue" 
                :placeholder="placeholder"
                @input="$emit('update:modelValue', dataValue)"
                :disabled="state === 'disabled' ? 'disabled' : null"
                >
                </textarea>
            </div>
            <span v-if="message" 
            class="mt-1 mx-1 inline-flex items-center" 
            :class="styling($styles.text.span_message)">
                <component
                :is="getMessageIcon" 
                fill="currentColor" 
                class="mr-1" 
                :class="styling($styles.text.icon_message)"/>
                {{message}}
            </span>
            <span v-if="helper" 
            class="mt-1 mx-1 text-neutral-400" 
            :class="styling($styles.text.span_helper)">
                {{helper}}
            </span>
        </div>
  </template>

<script>
  
export default  {
  name: 'TextVue',
  props: {
    modelValue: {
        type: String,
        default: ''
    },
    height: {
        type: String,
        validator(value) {
            return ['large', 'medium', 'small'].includes(value)
        }, 
        default: 'medium'
    },
    type: {
        type: String,
        validator(value) {
            return ['text', 'password'].includes(value)
        }, 
        default: 'text'
    },
    placeholder: {
        type: String,
        default: 'Insert text here'
    },
    label: {
        type: String,
        default: ''
    },
    message: {
        type: String,
        default: ''
    },
    helper: {
        type: String,
        default: ''
    },
    state: {
        type: String,
        validator(value) {
            return ['default', 'success', 'danger', 'warning', 'disabled'].includes(value)
        }, 
        default: 'default'
    },
  },
  watch: {
    modelValue: {
        handler() {
            this.dataValue = this.modelValue
        },
        deep: true
    },
    height: {
      handler() {},
      deep: true
    },
    type: {
      handler() {},
      deep: true
    },
    placeholder: {
      handler() {},
      deep: true
    },
    label: {
      handler() {},
      deep: true
    },
    message: {
      handler() {},
      deep: true
    },
    helper: {
      handler() {},
      deep: true
    },
    state: {
      handler() {},
      deep: true
    },
  },
  data () {
    return {
        dataValue: '',
    }
  },
  created(){
    this.dataValue = this.modelValue
  },
  computed: {
    getMessageIcon() {
      return this.$heroIcons[this.$styles.text.iconMessage[this.state]];
    },
  },
  methods: {
    getState(object){
        switch (this.state){
            case 'default':{
                return object.default
            }
            case 'success':{
                return object.success
            }
            case 'danger':{
                return object.danger
            }
            case 'warning':{
                return object.warning
            }
            case 'disabled':{
                return object.disabled
            }
            default:{
                return object.default
            }
        }

    },
    getHeight(object){
        switch (this.height){
            case 'large':{
                return object.large
            }
            case 'medium':{
                return object.medium
            }
            case 'small':{
                return object.small
            }
            default:{
                return object.medium
            }
        }
    },
    styling(object){
        var valren = ''
        object.height ? valren += this.getHeight(object.height) +' ' : null
        object.state ? valren += this.getState(object.state) +' ' : null
        return valren
    }
  },
}


</script>
  
  