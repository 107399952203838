export const styles = {
    'autocomplete': {
        'span_label' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
        },
        'text' : {
            'height' : {
                'large': 'h-text-input-large text-body-2 pl-[0.5rem]',  
                'medium': 'h-text-input-medium text-body-5 pl-[0.75rem]', 
                'small': 'h-text-input-small text-body-6 pl-[1rem]',
            },
            'state' : {
                'default': 'border-neutral-500 hover:border-neutral-300',
                'success': 'border-success-500 hover:border-success-300',
                'danger': 'border-danger-500 hover:border-danger-300',
                'warning': 'border-warning-500 hover:border-warning-300',
                'disabled': 'border-neutral-500 bg-neutral-400/10',
            }
        },
        'span_message' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
            'state' : {
                'default': 'text-neutral-600',
                'success': 'text-success-600',
                'danger': 'text-danger-600',
                'warning': 'text-warning-600',
                'disabled': 'text-neutral-600',
            }
        },
        'icon_message' : {
            'height' : {
                'large': 'h-5 w-5',
                'medium': 'h-3 w-3',
                'small': '',
            },
            'state' : {
                'default': '',
                'success': 'CheckCircleIcon',
                'danger': 'XCircleIcon',
                'warning': 'ExclamationIcon',
                'disabled': '',
            }
        },
        'span_helper' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
        },
    },
    'button': {
        'button' : {
            'height' : {
                'large': 'text-body-1 px-3 border min-h-button-large min-w-button-large',  
                'medium': 'text-body-4 px-2 border min-h-button-medium min-w-button-medium',  
                'small': 'text-body-5 px-1 border min-h-button-small min-w-button-small',  
            },
            'filling' : {
                'filled': {
                    'default': 'text-white bg-primary-500 border-primary-500 hover:bg-primary-400 active:bg-primary-600 hover:border-primary-400 active:border-primary-600',
                    'success': 'text-white bg-success-500 border-success-500 hover:bg-success-400 active:bg-success-600 hover:border-success-400 active:border-success-600',
                    'danger': 'text-white bg-danger-500 border-danger-500 hover:bg-danger-400 active:bg-danger-600 hover:border-danger-400 active:border-danger-600',
                    'warning': 'text-white bg-warning-500 border-warning-500 hover:bg-warning-400 active:bg-warning-600 hover:border-warning-400 active:border-warning-600',
                    'neutral': 'text-white bg-neutral-500 border-neutral-500 hover:bg-neutral-400 active:bg-neutral-600 hover:border-neutral-400 active:border-neutral-600',
                    'disabled': 'border text-neutral-500 bg-neutral-300 border-neutral-300'
                },
                'outlined': {
                    'default': 'text-primary-500 border-primary-500 bg-gray-50 hover:bg-primary-300 hover:text-white hover:border-primary-300 active:bg-primary-500 active:text-white active:border-primary-500',
                    'success': 'text-success-500 border-success-500 bg-gray-50 hover:bg-success-300 hover:text-white hover:border-success-300 active:bg-success-500 active:text-white active:border-success-500',
                    'danger': 'text-danger-500 border-danger-500 bg-gray-50 hover:bg-danger-300 hover:text-white hover:border-danger-300 active:bg-danger-500 active:text-white active:border-danger-500',
                    'warning': 'text-warning-500 border-warning-500 bg-gray-50 hover:bg-warning-300 hover:text-white hover:border-warning-300 active:bg-warning-500 active:text-white active:border-warning-500',
                    'neutral': 'text-neutral-500 border-neutral-500 bg-gray-50 hover:bg-neutral-300 hover:text-white hover:border-neutral-300 active:bg-neutral-500 active:text-white active:border-neutral-500',
                    'disabled': 'border text-neutral-500 bg-gray-50 border-neutral-300',
                },
                'text': {
                    'default': 'text-primary-500 border-gray-50 bg-gray-50 hover:text-primary-400 active:text-primary-600',
                    'success': 'text-success-500 border-gray-50 bg-gray-50 hover:text-success-400 active:text-success-600',
                    'danger': 'text-danger-500 border-gray-50 bg-gray-50 hover:text-danger-400 active:text-danger-600',
                    'warning': 'text-warning-500 border-gray-50 bg-gray-50 hover:text-warning-400 active:text-warning-600',
                    'neutral': 'text-neutral-500 border-gray-50 bg-gray-50 hover:text-neutral-400 active:text-neutral-600',
                    'disabled': 'border text-neutral-500 bg-white border-white',
                }
            },
            'icon_position' : {
                'left': 'flex-row',
                'right': 'flex-row-reverse',
            },
        },
        'icon' : {
            'height' : {
                'large': 'h-6 w-6',
                'medium': 'h-5 w-5',
                'small': 'h-4 w-4',
            },
        }
    },
    'checkbox': {
        'input' : {
            'height' : {
                'large': 'h-check-box-large w-check-box-large',  
                'medium': 'h-check-box-medium w-check-box-medium', 
                'small': 'h-check-box-small w-check-box-small', 
            },
        },
        'icon_check' : {
            'height' : {
                'large': 'h-7 w-7 top-[0.125rem] left-[0.125rem]',
                'medium': 'h-5 w-5 top-[0.125rem] left-[0.125rem]',
                'small': 'h-3 w-3 top-[0.25rem] left-[0.125rem]',
            },
        },
        'span_label' : {
            'height' : {
                'large': 'text-body-1',
                'medium': 'text-body-4',
                'small': 'text-body-5',
            },
        },
    },
    'datefield': {
        'span_label' : {
            'height' : {
                'large': 'text-body-5',
                'medium': 'text-body-6',
                'small': 'text-body-7',
            },
        },
        'text' : {
            'height' : {
                'large': 'h-text-input-large text-body-2 pl-[0.5rem]',  
                'medium': 'h-text-input-medium text-body-5 pl-[0.75rem]', 
                'small': 'h-text-input-small text-body-6 pl-[1rem]',
            },
            'state' : {
                'default': 'border-neutral-500 hover:border-neutral-300',
                'success': 'border-success-500 hover:border-success-300',
                'danger': 'border-danger-500 hover:border-danger-300',
                'warning': 'border-warning-500 hover:border-warning-300',
                'disabled': 'border-neutral-500 bg-neutral-400/10',
            }
        },
        'span_message' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
            'state' : {
                'default': 'text-neutral-600',
                'success': 'text-success-600',
                'danger': 'text-danger-600',
                'warning': 'text-warning-600',
                'disabled': 'text-neutral-600',
            }
        },
        'icon_message' : {
            'height' : {
                'large': 'h-5 w-5',
                'medium': 'h-3 w-3',
                'small': '',
            },
            'state' : {
                'default': '',
                'success': 'CheckCircleIcon',
                'danger': 'XCircleIcon',
                'warning': 'ExclamationIcon',
                'disabled': '',
            }
        },
        'span_helper' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
        },
        'icon_calendar' : {
            'height' : {
                'large': 'h-6 w-6 right-[0.75rem] inset-y-[0.75rem]',
                'medium': 'h-5 w-5 right-[0.65rem] inset-y-[0.65rem]',
                'small': 'h-4 w-4 right-[0.5rem] inset-y-[0.5rem]',
            },
        },
        'icon_x_mark' : {
            'height' : {
                'large': 'h-6 w-6 right-[2.5rem] inset-y-[0.75rem]',
                'medium': 'h-5 w-5 right-[2.2rem] inset-y-[0.65rem]',
                'small': 'h-4 w-4 right-[1.7rem] inset-y-[0.5rem]',
            },
        }
    },
    'datetimefield': {
        'span_label' : {
            'height' : {
                'large': 'text-body-5',
                'medium': 'text-body-6',
                'small': 'text-body-7',
            },
        },
        'text' : {
            'height' : {
                'large': 'h-text-input-large text-body-2 pl-[0.5rem]',  
                'medium': 'h-text-input-medium text-body-5 pl-[0.75rem]', 
                'small': 'h-text-input-small text-body-6 pl-[1rem]',
            },
            'state' : {
                'default': 'border-neutral-500 hover:border-neutral-300',
                'success': 'border-success-500 hover:border-success-300',
                'danger': 'border-danger-500 hover:border-danger-300',
                'warning': 'border-warning-500 hover:border-warning-300',
                'disabled': 'border-neutral-500 bg-neutral-400/10',
            }
        },
        'span_message' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
            'state' : {
                'default': 'text-neutral-600',
                'success': 'text-success-600',
                'danger': 'text-danger-600',
                'warning': 'text-warning-600',
                'disabled': 'text-neutral-600',
            }
        },
        'icon_message' : {
            'height' : {
                'large': 'h-5 w-5',
                'medium': 'h-3 w-3',
                'small': '',
            },
            'state' : {
                'default': '',
                'success': 'CheckCircleIcon',
                'danger': 'XCircleIcon',
                'warning': 'ExclamationIcon',
                'disabled': '',
            }
        },
        'span_helper' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
        },
        'icon_calendar' : {
            'height' : {
                'large': 'h-6 w-6 right-[0.75rem] inset-y-[0.75rem]',
                'medium': 'h-5 w-5 right-[0.65rem] inset-y-[0.65rem]',
                'small': 'h-4 w-4 right-[0.5rem] inset-y-[0.5rem]',
            },
        },
        'icon_x_mark' : {
            'height' : {
                'large': 'h-6 w-6 right-[2.5rem] inset-y-[0.75rem]',
                'medium': 'h-5 w-5 right-[2.2rem] inset-y-[0.65rem]',
                'small': 'h-4 w-4 right-[1.7rem] inset-y-[0.5rem]',
            },
        }
    },
    'input': {
        'span_label' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
        },
        'text' : {
            'height' : {
                'large': 'h-text-input-large text-body-2 pl-[0.5rem]',  
                'medium': 'h-text-input-medium text-body-5 pl-[0.75rem]', 
                'small': 'h-text-input-small text-body-6 pl-[1rem]',
            },
            'state' : {
                'default': 'border-neutral-500 hover:border-neutral-300',
                'success': 'border-success-500 hover:border-success-300',
                'danger': 'border-danger-500 hover:border-danger-300',
                'warning': 'border-warning-500 hover:border-warning-300',
                'disabled': 'border-neutral-500 bg-neutral-400/10',
            }
        },
        'password' : {
            'height' : {
                'large': 'h-text-input-large text-body-2 pl-[2.5rem]',  
                'medium': 'h-text-input-medium text-body-5 pl-[2.25rem]', 
                'small': 'h-text-input-small text-body-6 pl-[1.75rem]', 
            },
            'state' : {
                'default': 'border-neutral-500 hover:border-neutral-300',
                'success': 'border-success-500 hover:border-success-300',
                'danger': 'border-danger-500 hover:border-danger-300',
                'warning': 'border-warning-500 hover:border-warning-300',
                'disabled': 'border-neutral-500 bg-neutral-400/10',
            }
        },
        'span_message' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
            'state' : {
                'default': 'text-neutral-600',
                'success': 'text-success-600',
                'danger': 'text-danger-600',
                'warning': 'text-warning-600',
                'disabled': 'text-neutral-600',
            }
        },
        'icon_message' : {
            'height' : {
                'large': 'h-5 w-5',
                'medium': 'h-3 w-3',
                'small': '',
            },
            'state' : {
                'default': '',
                'success': 'CheckCircleIcon',
                'danger': 'XCircleIcon',
                'warning': 'ExclamationIcon',
                'disabled': '',
            }
        },
        'span_helper' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
        },
        'icon_eye' : {
            'height' : {
                'large': 'h-6 w-6 left-[0.75rem] inset-y-[0.75rem]',
                'medium': 'h-5 w-5 left-[0.65rem] inset-y-[0.65rem]',
                'small': 'h-4 w-4 left-[0.5rem] inset-y-[0.5rem]',
            },
        }
    },
    'select': {
        'span_label' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
        },
        'select' : {
            'height' : {
                'large': 'h-text-input-large text-body-2 pl-[0.5rem]',  
                'medium': 'h-text-input-medium text-body-5 pl-[0.75rem]', 
                'small': 'h-text-input-small text-body-6 pl-[1rem]',
            },
            'state' : {
                'default': 'border-neutral-500 hover:border-neutral-300',
                'success': 'border-success-500 hover:border-success-300',
                'danger': 'border-danger-500 hover:border-danger-300',
                'warning': 'border-warning-500 hover:border-warning-300',
                'disabled': 'border-neutral-500 bg-neutral-400/10',
            }
        },
        'span_message' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
            'state' : {
                'default': 'text-neutral-600',
                'success': 'text-success-600',
                'danger': 'text-danger-600',
                'warning': 'text-warning-600',
                'disabled': 'text-neutral-600',
            }
        },
        'icon_message' : {
            'height' : {
                'large': 'h-5 w-5',
                'medium': 'h-3 w-3',
                'small': '',
            },
            'state' : {
                'default': '',
                'success': 'CheckCircleIcon',
                'danger': 'XCircleIcon',
                'warning': 'ExclamationIcon',
                'disabled': '',
            }
        },
        'span_helper' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
        },
    },
    'tag': {
        "div_1" : {
            'size' : {
                'large': 'h-[5rem] w-[5rem]',
                'small': 'h-[3rem] w-[3rem]'
            }
        },
        "div_2" : {
            'size' : {
                'large': 'h-[4rem] w-[4rem]',
                'small': 'h-[2.5rem] w-[2.5rem]'
            },
            'state' : {
                'awaiting': 'border-4 border-gray-500',
                'transit': 'border-4 border-warning-500',
                'delivered': 'border-4 border-success-500',
                'reported': 'border-4 border-danger-500'
            }
        },
        "svg" : {
            'size' : {
                'large': 'h-[3rem] w-[3rem]',
                'small': 'h-8 w-8'
            }
        },
        "div_3" : {
            'size' : {
                'large': 'bottom-[3rem] left-[3rem] h-6 w-6',
                'small': 'bottom-[2rem] left-[2rem] h-4 w-4'
            },
            'alert' : {
                'danger': 'bg-danger-500',
                'warning': 'bg-warning-500',
                'success': 'bg-success-500'
            }
        },
        "alert" : {
            'size' : {
                'large': 'h-5 w-5',
                'small': 'h-3 w-3'
            }
        }
    },
    'text': {
        'span_label' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
        },
        'text' : {
            'height' : {
                'large': 'h-[7rem] text-body-2 pt-[0.5rem] pl-[0.5rem]',  
                'medium': 'h-[5rem] text-body-2 pt-[0.5rem] pl-[0.5rem]', 
                'small': 'h-[3rem] text-body-2 pt-[0.5rem] pl-[0.5rem]',
            },
            'state' : {
                'default': 'border-neutral-500 hover:border-neutral-300',
                'success': 'border-success-500 hover:border-success-300',
                'danger': 'border-danger-500 hover:border-danger-300',
                'warning': 'border-warning-500 hover:border-warning-300',
                'disabled': 'border-neutral-500 bg-neutral-400/10',
            }
        },
        'password' : {
            'height' : {
                'large': 'h-[10rem] text-body-2 pl-[2.5rem]',  
                'medium': 'h-[6rem] text-body-5 pl-[2.25rem]', 
                'small': 'h-[3rem] text-body-6 pl-[1.75rem]', 
            },
            'state' : {
                'default': 'border-neutral-500 hover:border-neutral-300',
                'success': 'border-success-500 hover:border-success-300',
                'danger': 'border-danger-500 hover:border-danger-300',
                'warning': 'border-warning-500 hover:border-warning-300',
                'disabled': 'border-neutral-500 bg-neutral-400/10',
            }
        },
        'span_message' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
            'state' : {
                'default': 'text-neutral-600',
                'success': 'text-success-600',
                'danger': 'text-danger-600',
                'warning': 'text-warning-600',
                'disabled': 'text-neutral-600',
            }
        },
        'icon_message' : {
            'height' : {
                'large': 'h-5 w-5',
                'medium': 'h-3 w-3',
                'small': '',
            },
            'state' : {
                'default': '',
                'success': 'CheckCircleIcon',
                'danger': 'XCircleIcon',
                'warning': 'ExclamationIcon',
                'disabled': '',
            }
        },
        'span_helper' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
        },
    },
}
