<template lang="html">
    <div class="flex flex-col justify-start align-top select-none">
        <span v-if="label" class="mb-1 mx-1 text-neutral-700" 
        :class="styling($styles.datefield.span_label)">
            {{label}}
        </span>
            <div class="relative w-full">
                <div @click="open ? closeModal() : openModal($event)">
                    <input
                    class="rounded-text-input box-border border transition-all w-full outline-none focus:ring-1 focus:ring-inset focus:ring-offset-1 focus:border-primary-400 focus:ring-primary-400 shadow-sm hover:shadow-lg focus:shadow-xs"
                    type="text"
                    v-model="dataValue"
                    :class="styling($styles.datefield.text)"
                    :placeholder="placeholder"
                    disabled
                    />
                </div>
                <component 
                v-show="dataValue != null"
                :is="$heroIcons['XMarkIcon']" 
                :class="styling($styles.datefield.icon_x_mark)" 
                class="absolute stroke-1 stroke-neutral-600" 
                @click="() => {
                    dataValue = null
                    $emit('update:modelValue', this.dataValue)
                }"
                />
                <component :is="$heroIcons['CalendarDaysIcon']" 
                :class="styling($styles.datefield.icon_calendar)" 
                class="absolute stroke-1 stroke-neutral-600" 
                @click="open ? closeModal() : openModal($event)"
                />
                <div v-if="open"
                class="fixed top-0 left-0 z-40 h-full w-full"
                @click="open = false"
                ></div>
                <div v-if="open" class="absolute right-0 z-50 shadow-xl bg-gray-50 rounded-modal p-2"
                :class="top ? 'bottom-[98%]' : 'top-[98%]'">
                    <div class="flex flex-col justify-center">
                        <div class="flex flex-row justify-center w-full p-2">
                            <ul class="inline-flex items-center -space-x-px w-full">
                                <li class="border border-gray-400 rounded-l-checkbox p-1 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300" @click="previousMonth()">
                                    <component :is="$heroIcons['ChevronLeftIcon']" fill="none" class="stroke-1 h-7 w-7" />
                                </li>
                                <li class="w-full border border-gray-400 p-1 text-body-2 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300">
                                    <div class="h-7 w-full px-1 flex items-center justify-center">{{ getMonthName().substr(0,4).toUpperCase() }} - {{ getYear() }}</div>
                                </li>
                                <li class="border border-gray-400 rounded-r-checkbox p-1 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300" @click="nextMonth()">
                                    <component :is="$heroIcons['ChevronRightIcon']" fill="none" class="stroke-1 h-7 w-7" />
                                </li>
                            </ul>
                        </div>
                        <table class="w-full">
                            <thead>
                                <tr>
                                    <th class="text-body-5 text-gray-400 text-center">LUN</th>
                                    <th class="text-body-5 text-gray-400 text-center">MAR</th>
                                    <th class="text-body-5 text-gray-400 text-center">MER</th>
                                    <th class="text-body-5 text-gray-400 text-center">JEU</th>
                                    <th class="text-body-5 text-gray-400 text-center">VEN</th>
                                    <th class="text-body-5 text-gray-400 text-center">SAM</th>
                                    <th class="text-body-5 text-gray-400 text-center">DIM</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(week,index) in getWeeks()" :key="index">
                                    <td v-for="day in week" :key="day.date"
                                    class="text-center w-10 !m-0"
                                    @click="selectDate(day.fullDate)">
                                        <div class="rounded-infinity p-2 w-10 hover:bg-gray-200 hover:text-gray-900 flex justify-center items-center"
                                        :class="getDateClass(day)">
                                            {{ day.date }}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <span v-if="message" 
            class="mt-1 mx-1 inline-flex items-center" 
            :class="styling($styles.datefield.span_message)">
                <component
                :is="$heroIcons[$styles.datefield.icon_message[state]]" 
                fill="currentColor" 
                class="mr-1" 
                :class="styling($styles.datefield.icon_message)"/>
                {{message}}
            </span>
            <span v-if="helper" 
            class="mt-1 mx-1 text-neutral-400" 
            :class="styling($styles.datefield.span_helper)">
                {{helper}}
            </span>
        </div>
  </template>

<script>

import SelectVue from './Select.vue'
  
export default  {
  name: 'DatetimefieldVue',
  components:{
    SelectVue
  },
  props: {
    modelValue: {
        type: String,
        default: ''
    },
    height: {
        type: String,
        validator(value) {
            return ['large', 'medium', 'small'].includes(value)
        }, 
        default: 'medium'
    },
    placeholder: {
        type: String,
        default: 'Insert text here'
    },
    label: {
        type: String,
        default: ''
    },
    message: {
        type: String,
        default: ''
    },
    helper: {
        type: String,
        default: ''
    },
    state: {
        type: String,
        validator(value) {
            return ['default', 'success', 'danger', 'warning', 'disabled'].includes(value)
        }, 
        default: 'default'
    },
  },
  watch: {
    modelValue: {
        handler() {
            this.dataValue = this.modelValue
        },
        deep: true
    },
    height: {
      handler() {},
      deep: true
    },
    placeholder: {
      handler() {},
      deep: true
    },
    label: {
      handler() {},
      deep: true
    },
    message: {
      handler() {},
      deep: true
    },
    helper: {
      handler() {},
      deep: true
    },
    state: {
      handler() {},
      deep: true
    },
  },
  data () {
    return {
      dataValue: '',
      date: null,
      day: null,
      open: false,
      top : null
    }
  },
  created(){
    this.date = new Date();
    this.day = this.toDateString(this.date)
    this.dataValue = this.modelValue
  },
  methods: {
    log(a){console.log(a)},

    openModal(event){
        this.top = Math.round((event.y - window.scrollY) / window.innerHeight);
        this.open = true
    },

    closeModal(){
        this.open = false
    },

    getMonthName() {
        return this.date.toLocaleString('fr-FR', { month: 'long' });
    },

    previousMonth(){
        this.date = new Date(this.date.setMonth(this.date.getMonth()-1));
    },

    nextMonth(){
        this.date = new Date(this.date.setMonth(this.date.getMonth()+1));
    },

    getYear(){
        return this.date.getFullYear()
    },

    getWeeks(){
        var weeks = []
        var today =  new Date()
        var firstDay = new Date(this.date.setDate(1))
        var d = new Date(this.date.setDate(1))
        if(firstDay.getDay() !== 1){
            do {
                d.setDate(d.getDate() - 1);
            } while (d.getDay() !== 1);
        }
        do {
            var week = []
            do {
                week.push({
                    date: d.getDate(),
                    sameMonth:  d.getMonth() == firstDay.getMonth(),
                    today: this.toDateString(d) == this.toDateString(today),
                    fullDate: this.toDateString(d)
                })
                d.setDate(d.getDate() + 1);
            } while (d.getDay() !== 1)
            weeks.push(week)
        } while (d.getMonth() == firstDay.getMonth())
        return weeks
    },

    toDateString(d){
        return ("0" +d.getDate()).slice(-2)+"/"+("0" +(d.getMonth()+1)).slice(-2)+"/"+d.getFullYear()
    },


    selectDate(day){
        this.day = day
        this.update()
        this.open = false
    },

    update(){
        this.dataValue = this.day
        this.$emit('update:modelValue', this.dataValue)
    },

    getDateClass(day){
        if(this.dataValue && this.dataValue.split(" ")[0] == day.fullDate){
            return "!text-body-5 !text-white !bg-primary-500"
        } else if(!day.sameMonth && !day.today){
            return "text-body-5 text-gray-400"
        } else if(!day.sameMonth && day.today){
            return "text-body-5 text-white"
        } else if(day.sameMonth && !day.today){
            return "text-body-4 text-gray-700"
        } else if(day.sameMonth && day.today){
            return "text-body-5 text-white bg-neutral-300"
        }
    },
    
    getState(object){
        switch (this.state){
            case 'default':{
                return object.default
            }
            case 'success':{
                return object.success
            }
            case 'danger':{
                return object.danger
            }
            case 'warning':{
                return object.warning
            }
            case 'disabled':{
                return object.disabled
            }
            default: {
                return object.default
            }
        }

    },
    getHeight(object){
        switch (this.height){
            case 'large':{
                return object.large
            }
            case 'medium':{
                return object.medium
            }
            case 'small':{
                return object.small
            }
            default: {
                return object.medium
            }
        }
    },
    styling(object){
        var valren = ''
        object.height ? valren += this.getHeight(object.height) +' ' : null
        object.state ? valren += this.getState(object.state) +' ' : null
        return valren
    }
  },
}


</script>
  
  