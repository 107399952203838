<template>
    <div class="w-full flex flex-row overflow-x-hidden bg-gray-100">
        <div class="fixed flex flex-col shadow-xl bg-gray-50 justify-start h-[calc(100vh-70px)] w-full md:h-[calc(100vh-100px)] md:w-34% lg:w-20% z-10">
            <div class="w-[50px] h-[50px] shadow-xl bg-success-500 text-gray-50 rounded-infinity absolute bottom-[30px] right-[30px] z-10 flex justify-center items-center"
            @click="() => {
                inputContact = null
                openContactSheet = true
            }">
                <component :is="$heroIcons['PlusIcon']" class="h-10 w-10"/>
            </div>
            <div class="w-full flex flex-row items-center justify-center p-4 border-b bg-gray-100">
                <InputVue
                class="w-full mr-2"
                :placeholder="'Rechercher'"
                :height="'large'"
                :state="'default'"
                :disabled="false"
                v-model="searchFilter"
                @update:modelValue="contactsRead()"
                />
            </div>
            <LoadingVue v-if="loading" class="mt-4"/>
            <div v-else class="overflow-y-scroll">
                <div v-for="object in contacts" 
                :key="object.id"
                class="border-b px-8 h-[70px] flex flex-col justify-center items-start"
                :class="contact && object.id === contact.id ? 'bg-gray-200':''"
                @click="contactRead(object.id)">
                    <div class="flex flex-row justify-center items-start">
                        <span class="text-body-2">{{ object.firstName.charAt(0).toUpperCase() + object.firstName.slice(1).toLowerCase() }}</span>
                        <span class="text-body-1 ml-1">{{ object.lastName.toUpperCase() }}</span>
                    </div>
                    <span class="text-body-5 text-gray-500">{{ object.companyName.toUpperCase() }}</span>
                </div> 
            </div>
        </div>
        <div class="absolute flex flex-col right-0 h-[calc(100vh-70px)] md:h-[calc(100vh-100px)] w-0 md:w-66% lg:w-80% "
        v-if="contact || !isMobile()">
            <div v-if="!contact && !isMobile()" class="flex w-full h-full items-center justify-center">
               <span>Aucun contact sélectionné</span>
            </div>
            <div v-else class="flex flex-col w-full h-full items-start justify-start bg-gray-50">
                <div class="flex mb-10 w-full flex-row justify-between items-center bg-neutral-700 text-white shadow-xl px-6 py-2"
                :class="!isMobile() ? 'flex-row items-start justify-between' : 'flex-col items-center justify-start'">
                    <div class="flex flex-row">
                        <div class="w-[70px] h-[70px] rounded-[70px] bg-gradient-to-b from-gray-300 via-gray-400 to-gray-300 text-title-1 text-gray-50 flex items-center justify-center shadow-sm m-4">
                            <span>{{ contact.firstName.charAt(0).toUpperCase() }}{{ contact.lastName.charAt(0).toUpperCase() }}</span>
                        </div>    
                        <div class="flex flex-row items-center justify-start">
                            <span class="text-title-2 text-gray-50">{{ contact.firstName.charAt(0).toUpperCase() + contact.firstName.slice(1).toLowerCase() }}</span>
                            <span class="text-title-1 ml-1 text-gray-100">{{ contact.lastName.toUpperCase() }}</span>
                        </div>
                    </div>    
                    <div class="flex flex-row">
                        <ButtonVue
                        class="mx-2"
                        :filling="'filled'"
                        :label="null"
                        :height="'large'"
                        :icon="'PencilSquareIcon'"
                        :state="'neutral'"
                        :disabled="false"
                        @click="() => {
                            inputContact = contact
                            openContactSheet = true
                        }"
                        />    
                        <ButtonVue
                        class="mx-2"
                        :filling="'filled'"
                        :label="null"
                        :height="'large'"
                        :icon="'XMarkIcon'"
                        :state="'danger'"
                        :disabled="false"
                        @click="() => {
                            if (window.confirm('Voulez-vous vraiment supprimer ce contact ? Cette action est irreversible...')) {
                                contacts.splice(contacts.findIndex(x => x.id === contact.id),1)
                                contact.delete();
                                contact = null
                            }
                        }"
                        />  
                    </div>  
                </div>
                <span class="text-title-3 my-2 px-6">
                    Entreprise : {{ contact.companyName }}
                </span>
                <div class="flex flex-row text-body-2 items-center justify-start text-gray-800 my-2 px-6">
                    <div class="has-tooltip flex items-center justify-center">
                        <span class='tooltip flex items-center justify-center rounded-button shadow-lg p-2 bg-gray-600 text-gray-200 -translate-y-full w-[6rem]'>{{ copy ? 'Copier ?' : 'Copié ✅'}}</span>
                        <ButtonVue
                        :label="null"
                        :filling="'outlined'"
                        :size="'large'"
                        :icon="'PhoneIcon'"
                        :state="'neutral'"
                        @mouseleave="copy = true"
                        @click="copyToClipboard(contact.phone)"
                        />
                    </div>
                    <span class="ml-6">{{contact.phone}}</span>
                </div>
                <div class="flex flex-row text-body-2 items-center justify-start text-gray-800 my-2 px-6">
                    <div class="has-tooltip flex items-center justify-center">
                        <span class='tooltip flex items-center justify-center rounded-button shadow-lg p-2 bg-gray-600 text-gray-200 -translate-y-full w-[6rem]'>{{ copy ? 'Copier ?' : 'Copié ✅'}}</span>
                        <ButtonVue
                        :label="null"
                        :filling="'outlined'"
                        :size="'large'"
                        :icon="'EnvelopeIcon'"
                        :state="'neutral'"
                        @mouseleave="copy = true"
                        @click="copyToClipboard(contact.email)"
                        />
                    </div>
                    <span class="ml-6">{{contact.email}}</span>
                </div>
                <div class="w-full py-4 my-4">
                    <div class="flex flex-row items-center justify-between py-6 bg-neutral-700 text-white shadow-xl px-6">
                        <span class="text-title-2">
                            Destinations
                        </span>
                        <ButtonVue class="float-right mx-2"
                        :label="null"
                        :filling="'filled'"
                        :size="'large'"
                        :icon="'PlusIcon'"
                        :state="'success'"
                        @click="() => {
                            inputDestination = null
                            openDestinationSheet = true
                        }"
                        />
                    </div>
                    <div class="flex flex-row items-center justify-between border-b py-2 px-6"
                    v-for="destination in contact.destinations" :key="destination.id">
                        <div class="flex flex-row text-body-2 items-center justify-start text-gray-800 my-2">
                            <div class="has-tooltip flex items-center justify-center">
                                <span class='tooltip flex items-center justify-center rounded-button shadow-lg p-2 bg-gray-600 text-gray-200 -translate-y-full w-[6rem]'>{{ copy ? 'Copier ?' : 'Copié ✅'}}</span>
                                <ButtonVue
                                v-if="destination.address"
                                :label="null"
                                :filling="'outlined'"
                                :size="'large'"
                                :icon="'HomeIcon'"
                                :state="'neutral'"
                                @mouseleave="copy = true"
                                @click="copyToClipboard(destination.address.display())"
                                />
                            </div>
                            <div class="flex flex-col items-start ml-6 ">
                                <span v-if="destination.name"  class="text-body-1">{{ destination.name }}</span>
                                <span v-if="destination.address">{{destination.address.display()}}</span>
                            </div>
                        </div>
                        <div class="flex flex-row">
                            <ButtonVue
                            class="mx-2"
                            :filling="'outlined'"
                            :label="null"
                            :height="'large'"
                            :icon="'PencilSquareIcon'"
                            :state="'neutral'"
                            :disabled="false"
                            @click="() => {
                                inputDestination = destination
                                openDestinationSheet = true
                            }"
                            />    
                            <ButtonVue
                            class="mx-2"
                            :filling="'outlined'"
                            :label="null"
                            :height="'large'"
                            :icon="'XMarkIcon'"
                            :state="'danger'"
                            :disabled="false"
                            @click="() => {
                                if (window.confirm('Voulez-vous vraiment supprimer cette destination ? Cette action est irreversible...')) {
                                    contact.destinations.splice(contact.destinations.indexOf(destination),1)
                                    destination.deleteContact();
                                }
                            }"
                            />  
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <RightSheetVue v-if="openContactSheet"
        @closeModal="openContactSheet = false">
            <template v-slot:body>
                <ContactFormVue
                :inputContact="inputContact"
                @contact="(newContact) => {
                    var index = contacts.findIndex(x => x.id === newContact.id) 
                    index >= 0 ? contacts[index] = newContact : contacts.push(newContact)
                    contacts.sort((a, b) => {
                        return a.lastName + ' ' + a.firstName < b.lastName + ' ' + b.firstName ? -1 : 1
                    })
                    contact = newContact
                    openContactSheet = false
                }"/>
            </template>
        </RightSheetVue>

        <RightSheetVue v-if="openDestinationSheet"
        @closeModal="openDestinationSheet = false">
            <template v-slot:body>
                <DestinationFormVue
                :inputContact="contact"
                :inputDestination="inputDestination"
                @destination="(newDestination) => {
                    var index = contact.destinations.findIndex(x => x.id === newDestination.id) 
                    index >= 0 ? contact.destinations[index] = newDestination : contact.destinations.push(newDestination)
                    openDestinationSheet = false
                }"/>
            </template>
        </RightSheetVue>
    </div>
</template>

<script>
import * as heroIcons from '@heroicons/vue/24/outline'
import InputVue from "@/components/elements/Input"
import ButtonVue from "@/components/elements/Button"
import LoadingVue from "@/components/elements/Loading"
import Contact from "@/lib/entities/Contact"
import ContactFormVue from "@/components/groups/ContactForm.vue"
import DestinationFormVue from "@/components/groups/DestinationForm.vue"
import RightSheetVue from "@/components/containers/RightSheet.vue"

export default ({
    name: 'ContactsVue',
    components:{
        InputVue,
        ButtonVue,
        LoadingVue,
        ContactFormVue,
        RightSheetVue,
        DestinationFormVue
    },
    props:{

    },
    data(){
        return {
            heroIcons: heroIcons,
            searchFilter: "",
            loading: true,
            contacts: [],
            contact: null,
            openContactSheet: false,
            openDestinationSheet: false,
            inputContact: null,
            inputDestination: null,
            window: window,
            copy: true
        }
    },
    created(){
        this.contactsRead()
    },
    methods:{
        isMobile() {
        return (this.$device === 'mobile' ||  this.$orientation === 'portrait') && window.innerWidth < 768
        },
        log(e){
            console.log(e)
        },
        async contactsRead(){
            var query = process.env.VUE_APP_API_BASE_PATH+'/contacts'
            if(this.searchFilter.length){
                query += "?q=" + this.searchFilter
            }
            await this.$axios
            .get(query, {withCredentials: true})
            .then(response => {
                if(response.status == 200){
                    this.loading = false
                    this.contacts = []
                    response.data['hydra:member'].forEach((contact) => {
                        this.contacts.push(new Contact(contact))
                    })
                }
            })
            this.contacts.sort((a, b) => {
                return a.lastName + ' ' + a.firstName < b.lastName + ' ' + b.firstName ? -1 : 1
            })
        },

        async contactRead(id){
            var query = process.env.VUE_APP_API_BASE_PATH+'/contacts/'+id
            await this.$axios
            .get(query, {withCredentials: true})
            .then(response => {
                if(response.status == 200){
                    this.contact = new Contact(response.data)
                }
            })
        },

        copyToClipboard(value){
            navigator.clipboard.writeText(value);
            this.copy = false
        },

    },
})
</script>