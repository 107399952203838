<template>
    <div>
        <transition name="fade" appear>
            <div
            class="w-full fixed z-10 left-0 overlay  h-[calc(100vh-70px)] top-[70px] md:h-[calc(100vh-100px)] md:top-[100px]" 
            @click="$emit('closeModal')"
            ></div>
        </transition>
        <transition name="slide" appear>
                <div 
                class="fixed z-20 right-0 h-[calc(100vh-70px)] top-[70px] md:h-[calc(100vh-100px)] md:top-[100px] w-85% md:w-85% lg:w-50% xl:34%"
                >
                    <div class="h-full w-full bg-gray-50 shadow-lg relative">
                        <div class="absolute bg-gray-50 text-gray-500 top-[1rem] w-[2rem] h-[5rem] left-[calc(-2rem)] rounded-l-button flex items-center justify-center" @click="$emit('closeModal')">
                            <component :is="heroIcons['ChevronRightIcon']" fill="none" class="stroke-1 h-7 w-7" />
                        </div>
                        <slot name="body">
                            This is the default body!
                        </slot>
                    </div>
                </div>
        </transition>
    </div>
</template>

<script>
import * as heroIcons from '@heroicons/vue/24/outline'

export default {
  name: 'RightSheetVue',
  emits:['closeModal'],
  data(){
    return {
        heroIcons: heroIcons,
        traqr: null,
    }
  },
}
</script>

<style scoped>

.slide-enter-from,
.slide-leave-to {
    transform: translate(100%, 0);
}

.slide-enter-active,
.slide-leave-active {
    transition: all .4s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: all .4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.overlay {
    background: #9ca3af;
    opacity: 0.5;
}

</style>