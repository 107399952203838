<template>
    <div class="flex flex-col items-start w-full font-lato">
        <div class="flex flex-col w-full">
            <div class="pl-5 bg-neutral-700 flex flex-row items-center mt-8 mb-4 h-[4rem]" :class="handlingUnitState == 'danger' ? 'bg-danger-700' : 'bg-neutral-700'">
                <p class="text-title-3 text-white"> {{ title }} </p>
            </div>
            <div class="w-full px-4">
                <table class="table-auto w-full">
                    <thead>
                        <tr class="!h-text-input-large pb-4">
                            <td class="px-1 w-13% align-bottom pb-4">
                            <InputVue 
                            :placeholder="'Nombre'"
                            :label="'Nombre'"
                            v-model="number"
                            :width="'full'"
                            :height="'large'"
                            :state="stateNumber"
                            /></td>
                            <td class="px-1 w-30% align-bottom pb-4">
                            <InputVue 
                            :placeholder="'Référence'"
                            :label="'Référence'"
                            v-model="reference"
                            :width="'full'"
                            :height="'large'"
                            :state="'default'"
                            /></td>
                            <td class="px-1 w-13% align-bottom pb-4">
                            <InputVue 
                            :placeholder="'Longueur'"
                            :label="'Longueur'"
                            v-model="length"
                            :width="'full'"
                            :height="'large'"
                            :state="stateLength"
                            /></td>
                            <td class="px-1 w-13% align-bottom pb-4">
                            <InputVue 
                            :placeholder="'Largeur'"
                            :label="'Largeur'"
                            v-model="width"
                            :width="'full'"
                            :height="'large'"
                            :state="stateWidth"
                            /></td>
                            <td class="px-1 w-13% align-bottom pb-4">
                            <InputVue 
                            :placeholder="'Hauteur'"
                            :label="'Hauteur'"
                            v-model="height"
                            :width="'full'"
                            :height="'large'"
                            :state="stateHeight"
                            /></td>
                            <td class="px-1 w-13% align-bottom pb-4">
                            <InputVue 
                            :placeholder="'Poids'"
                            :label="'Poids'"
                            v-model="weight"
                            :width="'full'"
                            :height="'large'"
                            :state="stateWeight"
                            /></td>
                            <td class="px-1 w-5% align-bottom pb-4">
                            <ButtonVue 
                            @click="addRow"
                            :filling="'filled'"
                            :label="null"
                            :height="'large'"
                            :icon="'PlusIcon'"
                            :state="'success'"
                            />
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="w-full px-4">
                <table class="table-auto w-full">
                    <tbody>
                        <tr v-for="(object, index) in handlingUnits" :key="index"
                        class="border-t py-2 !h-text-input-large">
                            <td class="px-1 !h-text-input-large w-13% text-center">{{object.number}}</td>
                            <td class="px-1 !h-text-input-large w-30% text-center">{{object.reference}}</td>
                            <td class="px-1 !h-text-input-large w-13% text-center">{{object.length}}</td>
                            <td class="px-1 !h-text-input-large w-13% text-center">{{object.width}}</td>
                            <td class="px-1 !h-text-input-large w-13% text-center">{{object.height}}</td>
                            <td class="px-1 !h-text-input-large w-13% text-center">{{object.weight}}</td>
                            <td class="px-1 !h-text-input-large w-5% text-center py-2">
                            <span>
                                <ButtonVue
                                @click="removeRow(index)"
                                :filling="'outlined'"
                                :label="null"
                                :height="'large'"
                                :icon="'MinusIcon'"
                                :state="'danger'"
                                />
                            </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import InputVue from "@/components/elements/Input.vue"
    import ButtonVue from "@/components/elements/Button.vue"
    
    export default {
      name: 'HandlingUnitTable',
      components: {
        InputVue,
        ButtonVue
      },
      props:{
        title: {
          type: String,
          default: "Unité de Manutention"
        },
        state: {
          type: String,
          default: "default"
        },
        handlingUnitState: {
          type: String,
          default: "default"
        },
        modelValue: {
          type: Object,
        }
      },
      data(){
        return {
            reference: null,
            number: "1",
            height: null,
            width: "80",
            length: "120",
            weight: null,
            stateNumber: 'default',
            stateHeight: 'default',
            stateWidth: 'default',
            stateLength: 'default',
            stateWeight: 'default',
            handlingUnits: [],
        }
      },
      watch: {
        handlingUnitState: {
                handler() {},
                deep: true
            },
        modelValue: {
                handler() {
                    this.handlingUnits = this.modelValue
                },
                deep: true
            },
        },
      methods:{
        isNumeric(str) {
            if (typeof str != "string") return false
            return !isNaN(+str) 
        },
        removeRow(index){
            this.handlingUnits.splice(index,1)
            this.$emit('update:modelValue', this.handlingUnits)
        },
        addRow(){
            if(this.width != null && this.length != null){
                var temoin = [false,false,false,false,false]
                if(this.isNumeric(this.number)){
                    temoin[0] = true 
                    this.stateNumber = 'default'
                } else {
                    temoin[0] = false
                    this.stateNumber = "danger"
                }
                if(this.isNumeric(this.height) || this.height == null){
                    temoin[1] = true 
                    this.stateHeight = 'default'
                } else {
                    temoin[1] = false
                    this.stateHeight = "danger"
                }
                if(this.isNumeric(this.width) && this.width){
                    temoin[2] = true 
                    this.stateWidth = 'default'
                } else if(!this.isNumeric(this.width)) {
                    temoin[2] = false
                    this.stateWidth = "danger"
                } else if(!this.width){
                    temoin[2] = false
                    this.stateWidth = "danger"
                }
                if(this.isNumeric(this.length) && this.length){
                    temoin[3] = true 
                    this.stateLength = 'default'
                } else if(!this.isNumeric(this.length)) {
                    temoin[3] = false
                    this.stateLength = "danger"
                } else if(!this.length){
                    temoin[3] = false
                    this.stateLength = "danger"
                }
                if(this.isNumeric(this.weight) || this.weight == null){
                    temoin[4] = true 
                    this.stateWeight = 'default'
                } else {
                    temoin[4] = false
                    this.stateWeight = "danger"
                }
                if(this.reference == null){
                    this.reference = "Référence " + (this.handlingUnits.length + 1)
                }
                if(temoin.reduce((partialSum, a) => partialSum + a, 0) == 5){
                    for(var i = 0; i < this.number ; i++){
                        this.handlingUnits.push({
                            "reference": this.reference,
                            "height": this.height === null ? null : parseInt(this.height),
                            "width": this.width === null ? null : parseInt(this.width),
                            "length": this.length === null ? null : parseInt(this.length),
                            "weight": this.weight === null ? null : parseInt(this.weight)
                        })
                    }
                    this.$emit('update:modelValue', this.handlingUnits)
                    this.reference = "Référence " + (this.handlingUnits.length + 1)
                    this.number = "1"
                    this.height = null
                    this.width = "80"
                    this.length = "120"
                    this.weight = null
                }
            }
        }
      }
    }
    </script>
    
    