import { createRouter, createWebHistory } from 'vue-router'

import TraqrCreateVue from '../views/TraqrCreateVue.vue'
import TraqrsReadVue from '../views/TraqrsReadVue.vue'
import AccountVue from '../views/AccountVue.vue'
import PageNotFoundVue from '../views/PageNotFoundVue.vue'
import ContactsVue from '../views/ContactsVue.vue'


const routes = [
  {
    path: '/',
    redirect: '/traqrs_read'
  },

  {
    path: '/traqr_create',
    name: 'Créer TraQR',
    component: TraqrCreateVue
  },
  {
    path: '/traqrs_read',
    name: 'TraQKING - Suivre TraQRs',
    component: TraqrsReadVue
  },
  {
    path: '/account',
    name: 'TraQKING - Compte',
    component: AccountVue
  },
  {
    path: '/contacts',
    name: 'TraQKING - Contacts',
    component: ContactsVue
  },
  {
    path: '/:catchAll(.*)*',
    name: "TraQKING - Page Not Found",
    component: PageNotFoundVue
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
