import CountryUtils from '@/lib/Country'

import axios from "axios"
import Destination from './Destination'
import Company from './Company'
import Scanner from './Scanner'
import Scan from './Scan'
import Reference from './Reference'
import Updatable from './Updatable'
import HandlingUnit from './HandlingUnit'


export default class Traqr {

    constructor(object = {}){
        this.build(object)
     }
 
    build(object){
        object.id ? this.id = object.id : this.id = null
        object.companyOwner ? this.companyOwner =  new Company(object.companyOwner) : this.companyOwner = null
        object.scanner ? this.scanner = new Scanner(object.scanner) : this.scanner = null
        
        if(object.scans){
            var scans = []
            object.scans.forEach((scan) => {
                scans.push(new Scan(scan))
            })
            this.scans = scans
            if(this.scans.filter(obj => obj.id !== null).length > 0) this.sortScansByDate()
        }
        
        var customReferences = []
        if (object.customReferences){
            object.customReferences.forEach((customReference) => {
                customReferences.push(new Reference(customReference))
            })
        }
        this.customReferences = customReferences

        var handlingUnits = []
        if(object.handlingUnits){
            object.handlingUnits.forEach((handlingUnit) => {
                handlingUnits.push(new HandlingUnit(handlingUnit))
            })
        }
        this.handlingUnits = handlingUnits

        object.shippedFrom ? this.shippedFrom = new Destination(object.shippedFrom) : this.shippedFrom = new Destination()
        object.consignedTo ? this.consignedTo = new Destination(object.consignedTo) : this.consignedTo = new Destination()
        object.comment ? this.comment = object.comment : this.comment = null
        object.shipperConstraints ? this.shipperConstraints = object.shipperConstraints : this.shipperConstraints = []
        object.shipperConstraintsComment ? this.shipperConstraintsComment = object.shipperConstraintsComment : this.shipperConstraintsComment = ""
        object.consigneeConstraints ? this.consigneeConstraints = object.consigneeConstraints : this.consigneeConstraints = []
        object.consigneeConstraintsComment ? this.consigneeConstraintsComment = object.consigneeConstraintsComment : this.consigneeConstraintsComment = ""
        object.goods ? this.goods = object.goods : this.goods = null
        object.loadingMeters ? this.loadingMeters = object.loadingMeters : this.loadingMeters = null
        object.state ? this.state = object.state : this.state = null

        object.shouldBePickedUpAt ? this.shouldBePickedUpAt = new Date(object.shouldBePickedUpAt) : this.shouldBePickedUpAt = null
        object.shouldBeDeliveredAt ? this.shouldBeDeliveredAt = new Date(object.shouldBeDeliveredAt) : this.shouldBeDeliveredAt = null

        object.updatable ? this.updatable = new Updatable(object.updatable) : this.updatable = null
        object.documents ? this.documents = object.documents : this.documents = null
        object.rawShippedFrom ? this.rawShippedFrom = object.rawShippedFrom : this.rawShippedFrom = null
        object.rawConsignedTo ? this.rawConsignedTo = object.rawConsignedTo : this.rawConsignedTo = null
    }

    validate(){
        if(!this.consignedTo.validate()) return false;
        if(!(this.handlingUnits.length || this.goods)) return false;
        return true
    }

    toPostJson(){
        return {
            "customReferences": this.customReferences,
            "handlingUnits": this.handlingUnits,
            "shippedFrom": this.shippedFrom.id ? this.shippedFrom.uri() : null,
            "consignedTo": this.consignedTo.uri() ,
            "rawShippedFrom": this.rawShippedFrom,
            "rawConsignedTo": this.rawConsignedTo,
            "shipperConstraints": this.shipperConstraints,
            "shipperConstraintsComment": this.shipperConstraintsComment,
            "consigneeConstraints": this.consigneeConstraints,
            "consigneeConstraintsComment": this.consigneeConstraintsComment,
            "goods": this.goods,
            "comment": this.comment,
            "shouldBePickedUpAt": this.shouldBePickedUpAt,
            "shouldBeDeliveredAt": this.shouldBeDeliveredAt,
          }
    }

    async save(pushGetParameters = null) {
        var query = process.env.VUE_APP_API_BASE_PATH+'/traqrs'
        if(pushGetParameters){
            const getParameters = new URLSearchParams(pushGetParameters).toString();
            query+= '?'+getParameters
        }
        await axios
        .post(query, this.toPostJson(),
        {
            headers: {
                'Content-Type': 'application/ld+json',
            },
            withCredentials: true 
        }).then(response => this.build(response.data))
        .catch(() => {
            //console.log(error)
            //window.location.replace('/traqrs_read')
        })
        return this
    }
    
    getReference(referenceType = 'ILU'){
        var key = Object.keys(this.customReferences).find(key => this.customReferences[key].type === referenceType)
        key = !key ? 0 : key 
        return this.customReferences[key].reference;
    }

    getNonIluReferenceIfExists(){
        var key = 0
        if(this.customReferences.length > 1){
            key = Object.keys(this.customReferences).find(key => this.customReferences[key].type === 'ILU')
            !key ? key = -1: null
            if(key == 0){
                key = 1
            } else if(key > 0) {
                key = 0
            } else {
                key = 0
            }
        }
        return this.customReferences[key].type === 'ILU' ? this.customReferences[key].reference : this.customReferences[key].type + ': ' + this.customReferences[key].reference;
    }

    getLastLocationCity(){
        if(this.getLastScanItem()){
            return this.getLastScanItem().address.city
        } else if(this.shippedFrom.address){
            return this.shippedFrom.address.city
        } else {
            return null
        }
    }

    getLastLocationStreet(){
        if(this.getLastScanItem()){
            return this.getLastScanItem().address.addressLine1
        } else if(this.shippedFrom.address){
            return this.shippedFrom.address.addressLine1
        } else {
            return null
        }
    }

    getLastLocationCountry(){
        if(this.getLastScanItem()){
            return CountryUtils.getCountryCode(this.getLastScanItem().address.country)
        } else if(this.shippedFrom.address !== null){
            return CountryUtils.getCountryCode(this.shippedFrom.address.country)
        } else {
            return null
        }
    }

    getConsigneeAddressCity(){
        return this.consignedTo.address.city
    }

    getConsigneeAddressStreet(){
        return this.consignedTo.address.addressLine1
    }

    getNumberOfScans(){
        return this.scans.length 
    }

    getCreationTime(){
        return new Date(this.updatable.createdAt).toLocaleTimeString()
    }

    getCreationDate(){
        return new Date(this.updatable.createdAt).toLocaleDateString()
    }

    getShouldBePickedUpAtTime(){
        return new Date(this.shouldBePickedUpAt).toLocaleTimeString()
    }

    getShouldBePickedUpAtDate(){
        return new Date(this.shouldBePickedUpAt).toLocaleDateString()
    }

    getShouldBeDeliveredAtTime(){
        return new Date(this.shouldBeDeliveredAt).toLocaleTimeString()
    }

    getShouldBeDeliveredAtDate(){
        return new Date(this.shouldBeDeliveredAt).toLocaleDateString()
    }

    getLastScanTime(){
        return this.getLastScanItem() ? new Date(this.getLastScanItem().updatable.lastUpdatedAt).toLocaleTimeString() : null;
    }

    getLastScanDate(){
        return this.getLastScanItem() ? new Date(this.getLastScanItem().updatable.lastUpdatedAt).toLocaleDateString() : null;
    }

    getLastScanItem(){
        if(this.scans.length > 0){
            for(var i = 0; i < this.scans.length; i++ ){
                if(this.scans[i].address && this.scans[i].address.longitude && this.scans[i].address.latitude){
                    return this.scans[i]
                }
            }
        }
    }

    sortScansByDate(){
        if(this.scans.length > 0){
            this.scans = this.scans.sort((a,b) => {
               return new Date(b.updatable.lastUpdatedAt).getTime() - new Date(a.updatable.lastUpdatedAt).getTime()
            })
        }
    }

    getConsigneeCountry(){
        return CountryUtils.getCountryCode(this.consignedTo.address.country)
    }

    getTotalHandlingUnits(){
        var count = 0;
        this.handlingUnits.forEach(element => element.number ? count += element.number : count += 1)
        return count
    }

    getDeliveryNote(){
        if(this.documents){
            let index = this.documents.findIndex(function(object) {
                return object.type == "delivery_note"
            })
            if(index >= 0) {
                const link = document.createElement('a')
                link.href = 'http://'+this.documents[index].uri
                //link.download = response.data.location
                link.target = "_blank"
                link.click()
                URL.revokeObjectURL(link.href)
                return;
            }
        } 
        window.alert('Attention, document en cours de création, veuillez patienter et réessayer dans quelques instants. Merci de votre compréhension.')
    }

    async getLabels(){
        if(this.documents){
            let index = this.documents.findIndex(function(object) {
                return object.type == "labels"
            })
            if(index >= 0) {
                const link = document.createElement('a')
                link.href = 'http://'+this.documents[index].uri
                //link.download = response.data.location
                link.target = "_blank"
                link.click()
                URL.revokeObjectURL(link.href)
                return;
            }
        } 
        window.alert('Attention, document en cours de création, veuillez patienter et réessayer dans quelques instants. Merci de votre compréhension.')
    }
}