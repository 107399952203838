<template>
    <div class="w-full flex flex-row"
    :class="!isMobile() ? 'h-[calc(100vh-100px)] top-[100px]' : 'h-[calc(100vh-70px)] top-[70px]'">
        <div class="flex flex-col justify-between mt-2 bg-gray-100 shadow-sm border-r"
        :class="!isMobile() ? 'w-15%' : 'w-[5.4rem]'"
        >
            <div>
                <div class="px-4 py-2 flex flex-col" v-for="(option,index) in menuOptions" :key="index" @click="selectedOption = option">
                    <div class="flex flex-row items-center justify-start rounded-button p-2" :class="selectedOption == option ? 'bg-gray-200' : null" @click="''">
                        <div class="border border-gray-500 flex items-center justify-around rounded-modal text-gray-500 shadow-xs p-1">
                            <component :is="heroIcons[option.icon]" fill="none" class="stroke-1 h-7 w-7" />
                        </div>
                        <span class="ml-2 text-body-2 text-gray-500 hidden lg:block">{{ option.title }}</span>
                    </div>
                </div>
            </div>
            <ButtonVue
            class="!m-4"
            :label="!isMobile() ? 'Se déconnecter' : null"
            :filling="'filled'"
            :height="'large'"
            :state="'danger'"
            :icon="'ArrowRightOnRectangleIcon'"
            @click="disconnect()"
            />
        </div>
        <div class="flex flex-col justify-between mt-2"
        :class="!isMobile() ? 'w-85%' : 'w-[calc(100%-5.4rem)]'"
        >  
            <component
            :is="selectedOption.component"
            ></component>

        </div>
    </div>
</template>

<script>

import ButtonVue from "@/components/elements/Button.vue"
import * as heroIcons from '@heroicons/vue/24/outline'

import MultiSelectVue from '@/components/elements/MultiSelect.vue'

import SettingIntegration from "@/components/groups/settings/Integration.vue"

export default ({
    name: 'AccountVue',
    components: {
        ButtonVue,
        SettingIntegration,
        MultiSelectVue
    },
    created(){
        this.selectedOption = this.menuOptions[0]
        this.$emit('loaded')
    },
    data(){
        return {
            value: [],
            heroIcons: heroIcons,
            menuOptions: [
                /*{
                    title:"Informations Personnelles",
                    icon:"UserIcon",
                    role: {
                        read: "user",
                        write: "user"
                    },
                    component: "div",
                },
                /*{
                    title:"Statistiques",
                    icon:"ChartBarIcon",
                    role: {
                        read: "user",
                        write: "user"
                    },
                    settingsUri: "",
                    options: [

                    ]
                },
                {
                    title:"Entreprise",
                    icon:"HomeIcon",
                    role: {
                        read: "user",
                        write: "user"
                    },
                    component: "div",
                },
                {
                    title:"Entrepôts",
                    icon:"MapIcon",
                    role: {
                        read: "user",
                        write: "user"
                    },
                    settingsUri: "",
                },
                {
                    title:"Utilisateurs",
                    icon:"UsersIcon",
                    role: {
                        read: "user",
                        write: "user"
                    },
                    component: "div",
                },
                {
                    title:"Contacts",
                    icon:"UserGroupIcon",
                    role: {
                        read: "user",
                        write: "user"
                    },
                    component: "div",
                },
                {
                    title:"References",
                    icon:"ClipboardIcon",
                    role: {
                        read: "user",
                        write: "user"
                    },
                    component: "div",
                },
                /*{
                    title:"Parainnage",
                    icon:"LinkIcon",
                    role: {
                        read: "user",
                        write: "user"
                    },
                    settingsUri: "",
                    options: [

                    ]
                },*/
                /*{
                    title:"API",
                    icon:"KeyIcon",
                    role: {
                        read: "user",
                        write: "user"
                    },
                    component: "div",
                },*/
                {
                    title:"Integrations",
                    icon:"CommandLineIcon",
                    role: {
                        read: "user",
                        write: "user"
                    },
                    component: "SettingIntegration",
                },
            ],
            selectedOption: null,
        }
    },



    methods:{
        isMobile() {
        return (this.$device === 'mobile' ||  this.$orientation === 'portrait') && window.innerWidth < 768
        },

        log(e){
            console.log(e)
        },

        async disconnect(){
            await this.$axios
            .post(process.env.VUE_APP_API_BASE_PATH+'/logout', 
            {}, { withCredentials: true })
            .then(response => {
                if(response.status == 200){
                    window.location.replace("https://www.traq-king.com");
                }
            })
        },

        async switchTab(index){
            this.selectedIndex = index;
            switch (index){
                case 0 :
                case 1 :
                case 2 :
                case 3 :
                case 4 :
                case 5 :
                case 6 :
                case 7 :
            }
        },

    }
})
</script>