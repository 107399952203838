<template lang="html">
    <div class="flex items-end justify-start" 
    :class="styling($styles.tag.div_1)"
    >
        <div 
        class="bg-neutral-700 flex items-center justify-around rounded-modal text-white shadow-xs relative" 
        :class="styling($styles.tag.div_2)"
        >
            <svg v-if="type === 'traqr'" 
            class="stroke-1" 
            :class="styling($styles.tag.svg)"
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor" 
            stroke-width="1" 
            xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 12.8 6.8 9.8 1.6 12.8M12 12.8 6.8 15.8M12 12.8V18L6.8 21M1.6 12.8 6.8 15.8M1.6 12.8V18L6.8 21M6.8 15.8V20.936M17.2 4.6 12 1.5 6.8 4.6M17.2 4.6 12 7.6M17.2 4.6V9.8L12 12.8M6.8 4.6 12 7.6M6.8 4.6V9.8L12 12.8M12 7.6V12.8M22.4 12.8 17.2 9.8 12 12.8M22.4 12.8 17.2 15.8M22.4 12.8V18L17.2 21M12 12.8 17.2 15.8M12 12.8V18L17.2 21M17.2 15.8V21"
                />
            </svg>
            <component v-else-if="type === 'handlingUnit'" 
            :is="$heroIcons['CubeIcon']" 
            fill="none" 
            class="stroke-1" 
            :class="styling($styles.tag.svg)"
            />
            <component v-else-if="type ==='route'" 
            :is="$heroIcons['TruckIcon']" 
            fill="none" 
            class="stroke-1" 
            :class="styling($styles.tag.svg)"
            />
            <div v-if="alert" 
            class="absolute flex items-center justify-center rounded-infinity text-white"
            :class="styling($styles.tag.div_3)"
            >
                <component :is="alertIcon" 
                fill="white" 
                class="stroke-1" 
                :class="styling($styles.tag.alert)"
                />
            </div>
        </div>
    </div>  
  </template>
  
  <script>
  
export default  {
      name: 'TagVue',
      props: {
        type: {
            type: String,
            validator(value) {
                return ['traqr', 'handlingUnit', 'route'].includes(value)
            }, 
            default: "traqr"
        },
        alert: {
            type: String,
            validator(value) {
                return ['danger', 'warning','success'].includes(value)
            }, 
            default: null
        },
        size: {
            type: String, 
            validator(value) {
                return ['large', 'small'].includes(value)
            },
            default: 'large'
        },
        state: {
            type: String,
            validator(value) {
                return ['delivered', 'awaiting', 'transit', 'reported'].includes(value)
            }, 
            default: 'awaiting'
        },
      },
      watch: {
        type: {
          handler() {
          },
          deep: true
        },
        alert: {
          handler() {
            switch(this.alert){
                case 'danger': return this.alertIcon = this.$heroIcons['XCircleIcon'];
                case 'warning': return this.alertIcon = this.$heroIcons['ExclamationCircleIcon'];
                case 'success': return this.alertIcon = this.$heroIcons['CheckCircleIcon'];
            }
          },
          deep: true
        },
        size: {
          handler() {
          },
          deep: true
        },
        state: {
          handler() {
          },
          deep: true
        },
      },
      data(){
        return {
            alertIcon: null
        }
      },
      created(){
        switch(this.alert){
                case 'danger': return this.alertIcon = this.$heroIcons['XCircleIcon'];
                case 'warning': return this.alertIcon = this.$heroIcons['ExclamationCircleIcon'];
                case 'success': return this.alertIcon = this.$heroIcons['CheckCircleIcon'];
            }
      },
      methods: {
        getAlert(object){
            switch (this.alert){
                case 'danger':{
                    return object.danger
                }
                case 'warning':{
                    return object.warning
                }
                case 'success':{
                    return object.success
                }
            }
        },
        getSize(object){
            switch (this.size){
                case 'large':{
                    return object.large
                }
                case 'small':{
                    return object.small
                }
            }
        },
        getState(object){
            switch (this.state){
                case 'delivered':{
                    return object.delivered
                }
                case 'awaiting':{
                    return object.awaiting
                }
                case 'transit':{
                    return object.transit
                }
                case 'reported':{
                    return object.reported
                }
            }
        },
        
        styling(object){
            var valren = ''
            object.alert ? valren += this.getAlert(object.alert) +' ' : null
            object.size ? valren += this.getSize(object.size) +' ' : null
            object.state ? valren += this.getState(object.state) +' ' : null
            return valren
        }
      },
  }
  
  
  </script>
  