<template>
    <div class="relative w-full select-none">
        <InputVue
        :placeholder="dataValue.length == 0 ? placeholder : ''"
        :height="height"
        :state="'disabled'"
        />
        <div class="absolute top-0 w-full z-10 flex flex-row justify-start items-center overflow-hidden px-2"
        :class="'h-text-input-'+height"
        @click="openDropDown = !openDropDown">
            <div v-for="(object, index) in dataValue"
            :key="index" 
            class="bg-primary-400 text-gray-100 mx-1 px-2 py-1 rounded-checkbox flex flex-row justify-between items-center">
                {{ object.code }}
                <component :is="$heroIcons['XMarkIcon']" class="h-4 w-4 ml-1" 
                @click="() => {
                    dataValue.splice(index,1)
                    openDropDown = !openDropDown
                    $emit('update:modelValue', dataValue)
                }"/>
            </div>
            <div class="absolute pointer-events-none top-[50%] right-0 h-full -translate-y-2/4 bg-white rounded-r-text-input flex justify-start items-center border-t border-r border-b pr-2 border-neutral-500"
            :class="'h-text-input-'+height" @click="openDropDown = !openDropDown">
                <component :is="openDropDown ? $heroIcons['ChevronUpIcon'] : $heroIcons['ChevronDownIcon']" class="stroke-black h-6 w-6"/>
            </div>
        </div>
        <div v-show="openDropDown" class="absolute top-[98%] w-full z-30 shadow-xl bg-gray-50">
            <div v-for="(object, index) in dataOptions" 
            :key="index" 
            class="p-2 border-b flex flex-row justify-between select-none" 
            @click="() => {
                if(dataValue.findIndex(a => a.code === object.code) < 0){
                    dataValue.push(object)    
                } else {
                    dataValue.splice(dataValue.findIndex(a => a.code === object.code),1)
                }
                $emit('update:modelValue', dataValue)
            }">
                <span class="text-body-2 text-neutral-800">{{ object.name }}</span>
                <span class="text-body-3 text-neutral-300">{{ dataValue.findIndex(a => a.code === object.code) < 0 ? 'Ajouter' : 'Supprimer' }}</span>
            </div>
        </div>
    </div>
</template>
    
    
    
<script>
import InputVue from '@/components/elements/Input.vue'

export default {
    name: 'AddressAutocomplete',
    components:{
        InputVue
    },
    data(){
        return {
            dataValue: [],
            dataOptions: [],
            openDropDown: false
        }
    },
    created(){
        this.dataValue = this.modelValue
        this.dataOptions = this.options
    },  
    props: {
        height: {
            type: String,
            validator(value) {
                return ['large', 'medium', 'small'].includes(value)
            }, 
            default: 'medium'
        },
        placeholder: {
            type: String,
            default: 'Selection...'
        },
        options: {
            type: Array, 
            default: () => {
                return []
            }
        },
        modelValue: {
            type: Array, 
            default: () => {
                return []
            }
        }
    },
    watch:{
        modelValue: {
            handler() {
                this.dataValue = this.modelValue
            },
            deep: true
        },
        height: {
            handler() {},
            deep: true
        },
        placeholder: {
            handler() {},
            deep: true
        },
        options: {
            handler() {
                this.dataOptions = this.options
            },
            deep: true
        },
    },
    methods: {
        addTag(){

        }
    }
}
    </script>