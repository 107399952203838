<template>
    <div class="w-full p-4 flex flex-col">
        <span class="text-title-2 text-black mb-4">Intégrations</span>
        <span class="text-title-3 text-gray-600">Intégrations installées</span>
        <div v-if="loading" class="flex flex-col w-full justify-center align-center p-10">
            <LoadingVue/>
        </div>
        <div v-else class="flex flex-row overflow-x-scroll p-10">
            <div v-for="(integration, index) in accountIntegrations" :key="index"
            class="h-[200px] flex flex-col w-[350px] p-2 border shadow-lg rounded-modal">
                <div class="flex flex-row justify-between w-full">
                    <img :src="integration.logo" width="120">
                    <ButtonVue
                    class="m-2"
                    :filling="'filled'"
                    :label="null"
                    :height="'medium'"
                    :icon="'CogIcon'"
                    :state="'neutral'"
                    @click="setIntegration(integration)"
                    />
                </div>
                <div class="flex flex-row w-full justify-between">
                    <span class="text-body-3 text-gray-400 mt-4">Description</span>
                    <span class="text-body-3 text-gray-400 mt-4">Version {{ integration.version }}</span>
                </div>
                <span class="text-body-5 text-black mt-2">
                    {{ integration.description }}
                </span>
            </div>
        </div>
        <div class="flex flex-col w-full border-t pt-4">
            <div class="flex flex-row justify-between w-full flex-wrap">
                <span class="text-title-3 text-gray-600">Ajouter une intégration</span>
                <div class="flex flex-row">
                    <ButtonVue
                    :filling="'filled'"
                    :label="null"
                    :height="'large'"
                    :icon="'MagnifyingGlassIcon'"
                    :state="'disabled'"
                    />
                    <InputVue 
                    class="ml-2 w-[300px]"
                    :placeholder="'Rechercher votre logiciel'"
                    :height="'large'"
                    :state="'default'"
                    />
                </div>
                
            </div>
            <div v-if="loading" class="flex flex-col w-full justify-center align-center p-10">
                <LoadingVue/>
            </div>
            <div v-else class="flex flex-row w-full flex-wrap p-10">
                <div v-for="(integration, index) in integrations" :key="index"
                class="h-[200px] flex flex-col w-[350px] p-2 border shadow-lg rounded-modal">
                    <div class="flex flex-row justify-between w-full">
                        <img :src="integration.logo" width="120">
                        <ButtonVue
                        class="m-2"
                        :filling="'filled'"
                        :label="null"
                        :height="'medium'"
                        :icon="'PlusIcon'"
                        :state="'success'"
                        @click="addIntegration(integration)"
                        />
                    </div>
                    <div class="flex flex-row w-full justify-between">
                        <span class="text-body-3 text-gray-400 mt-4">Description</span>
                        <span class="text-body-3 text-gray-400 mt-4">Version {{ integration.version }}</span>
                    </div>
                    <span class="text-body-5 text-black mt-2">
                        {{ integration.description }}
                    </span>
                </div>
            </div>
        </div>
        <RightSheet v-if="currentIntegration"
        @closeModal="currentIntegration = null">
            <template v-slot:body>
                <div class="flex flex-col justify-between h-full">
                    <div class="flex flex-col p-4">
                        <span class="text-title-2 text-black mb-4">Paramétrer l'intégration {{ currentIntegration.software }}</span>
                        <div v-for="(component, index) in currentIntegration.settings" :key="index"
                        class="mt-2">
                            <component :is="component.field"
                            :label="component.label"
                            :placeholder="component.placeholder"
                            :type="component.type"
                            :options="component.options"
                            v-model="currentIntegration.value[component.variable]"
                            />
                        </div>
                    </div>
                    <div class="flex flex-row justify-between w-full p-4">
                        <ButtonVue
                        class="m-2"
                        :filling="'filled'"
                        :label="'Supprimer'"
                        :height="'large'"
                        :state="'danger'"
                        @click="deleteIntegration()"
                        />
                        <ButtonVue
                        class="m-2"
                        :filling="'filled'"
                        :label="'Enregistrer'"
                        :height="'large'"
                        :state="'default'"
                        @click="saveIntegration()"
                        />
                    </div>
                </div>
            </template>
        </RightSheet>
    </div>

</template>

<script>
import SelectVue from "@/components/elements/Select.vue"
import InputVue from "@/components/elements/Input.vue"
import ButtonVue from "@/components/elements/Button.vue"
import RightSheet from "@/components/containers/RightSheet.vue"
import LoadingVue from "@/components/elements/Loading.vue"

export default ({
    name: 'SettingIntegration',
    components: {
        SelectVue,
        InputVue,
        ButtonVue,
        RightSheet,
        LoadingVue
    },
    data(){
        return {
            loading: true,
            integrations : [],
            settingId: null,
            accountIntegrations : [],
            currentIntegration : null,
        }
    },
    methods: {
        switchTab(n){
            this.tab = n
        },
        async retrieveIntegrations(getParameter = ""){
            await this.$axios
            .get(process.env.VUE_APP_API_BASE_PATH+'/integrations?software='+getParameter, { withCredentials: true })
            .then(response => {
                if(response.status == 200){
                    this.integrations = response.data["hydra:member"]
                    this.integrations = this.integrations.filter(object1 => {
                        return this.accountIntegrations.find(object2 => {
                            return object2.software === object1.software
                        }) ? false: true
                    });
                }
            })
            .catch(() =>{
            });
        },

        async retrieveAccountIntegrations(){
            await this.$axios
            .get(process.env.VUE_APP_API_BASE_PATH+'/settings?type=Integrations&identifier=integrations', { withCredentials: true })
            .then(response => {
                if(response.status == 200){
                    this.accountIntegrations = response.data['hydra:member'].length ? response.data['hydra:member'][0].value : null;
                    this.accountIntegrations ? this.settingId = response.data['hydra:member'][0].id : null;
                }
            })
        },

        async saveIntegrations(){
            await this.$axios
            .patch(process.env.VUE_APP_API_BASE_PATH+'/settings/'+this.settingId,
            {
                value: this.accountIntegrations
            },
            { 
                withCredentials: true,
                headers: {
                    'Content-Type': "application/merge-patch+json"
                }
            })
        },

        async addIntegration(integration){
            let value = {};
            integration.settings.forEach(setting => {
                value[setting.variable] = null
            })
            integration.value = value
            this.currentIntegration = integration

            this.accountIntegrations.push(this.currentIntegration)
            this.integrations = this.integrations.filter(object => {
                return object.software === this.currentIntegration.software ? false: true
            });
            await this.saveIntegrations()
        },

        async deleteIntegration(){
            this.integrations.push(this.currentIntegration)
            this.accountIntegrations = this.accountIntegrations.filter(object => {
                return object.software === this.currentIntegration.software ? false: true
            });
            this.currentIntegration = null
            await this.saveIntegrations()
        },

        async saveIntegration(){
            let index = this.accountIntegrations.findIndex(object => {
                return object.software === this.currentIntegration.software
            })
            this.accountIntegrations[index] =  this.currentIntegration;    
            this.currentIntegration = null
            await this.saveIntegrations()
        },

        setIntegration(integration){
            this.currentIntegration = integration
        },

        findAccountIntegrationBySoftWare(software){
            return software
        }

        
    },
    async created(){
        await this.retrieveIntegrations()
        await this.retrieveAccountIntegrations()
        this.loading = false;
    }
})
</script>