<template>
    <div 
    v-if="!isMobile()" 
    class="w-full flex flex-col justify-between"
    >
        <div class="w-full flex flex-row border-b items-start justify-between h-[7rem]">
            <div class="flex flex-row items-center justify-start w-35% my-auto">
                <div class="pl-4 pb-4">
                    <TagVue 
                    :size="'large'"
                    :alert="traqr.scans.length && traqr.scans[0].report ? getAlertType(traqr.scans[0].report.state.split('-')[0]) : null"
                    :type="'traqr'"
                    :state="traqr.state"
                    />
                </div>
                <div class="flex flex-col items-start justify-center">
                    <div class="has-tooltip flex items-center justify-start w-full">
                        <span class='tooltip flex items-center justify-center rounded-button shadow-lg p-2 bg-gray-600 text-gray-200 translate-y-full'
                        >{{ copy ? 'Copier le numéro de suivi ?' : 'Copié ✅'}}</span>
                        <span class="text-body-3 lg:text-body-1 xl:text-title-3 text-center"
                        @mouseleave="copy = true"
                        @click="copyToClipboard(traqr.id)"
                        >{{traqr.getNonIluReferenceIfExists()}}</span>
                    </div>
                    <div class="flex flex-row items-center justify-center">
                        <span class="text-body-5 xl:text-body-2 text-gray-500">Mis à jour : {{traqr.getLastScanDate()}} {{traqr.getLastScanTime()}}</span>
                    </div>
                    <div class="flex flex-row items-center justify-center">
                        <span class="text-body-5 xl:text-body-2 text-gray-500">Création : {{traqr.getCreationDate()}} {{traqr.getCreationTime()}}</span>
                    </div>
                </div>
            </div>
            <div class="flex flex-row w-70% justify-end h-full">
                <div class="flex flex-row items-start py-4 w-50%">
                    <div class="flex flex-col items-start justify-start">
                        <span class="text-body-4 xl:text-body-1 text-gray-800 mb-1">Dernière position : </span>
                        <div v-show="traqr.shouldBePickedUpAt" class="flex flex-row items-center justify-center">
                            <span class="text-body-5 xl:text-body-2 text-gray-500">Enlèvement : {{traqr.getShouldBePickedUpAtDate()}} {{traqr.getShouldBePickedUpAtTime()}}</span>
                        </div>
                        <div class="flex flex-row items-center justify-center">
                            <div class="flex items-center justify-center" v-if="traqr.getLastLocationCountry()">
                                <CountryFlag :country='traqr.getLastLocationCountry()' class="!m-0 rounded-checkbox shadow-sm"/>
                            </div>
                            <div class="flex flex-col items-start justify-center ml-2">
                                <span class="text-body-5 xl:text-body-2 text-gray-500">{{traqr.getLastLocationStreet()}}</span>
                                <span class="text-body-4 xl:text-body-1 ">{{traqr.getLastLocationCity()}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row items-start py-4 w-50%">
                    <div class="flex flex-col items-start justify-start">
                        <span class="text-body-4 xl:text-body-1 text-gray-800 mb-1">Destination : </span>
                        <div v-show="traqr.shouldBeDeliveredAt" class="flex flex-row items-center justify-center">
                            <span class="text-body-5 xl:text-body-2 text-gray-500">Livraison : {{traqr.getShouldBeDeliveredAtDate()}} {{traqr.getShouldBeDeliveredAtTime()}}</span>
                        </div>

                        <div class="flex flex-row items-center justify-center">
                            <div class="flex items-center justify-center" v-if="traqr.getConsigneeCountry()">
                                <CountryFlag :country='traqr.getConsigneeCountry()' class="!m-0 rounded-checkbox shadow-sm"/>
                            </div>
                            <div class="flex flex-col items-start justify-center ml-2">
                                <span class="text-body-5 xl:text-body-2 text-gray-500">{{traqr.getConsigneeAddressStreet()}}</span>
                                <span class="text-body-4 xl:text-body-1">{{traqr.getConsigneeAddressCity()}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row justify-end items-center my-auto flex-wrap px-4 w-20% h-full">
                    <div class="flex flex-row">
                        <div class="has-tooltip flex items-center justify-center">
                            <span class='tooltip flex items-center justify-center rounded-button shadow-lg p-2 bg-gray-600 text-gray-200 -translate-y-full'>Bon d'expédition</span>
                            <ButtonVue class="relative m-1"
                            @click="traqr.getDeliveryNote()"
                            :label="null"
                            :filling="'outlined'"
                            :height="'medium'"
                            :icon="'ClipboardDocumentListIcon'"
                            :state="'neutral'"
                            />
                        </div>
                        <!--<div class="has-tooltip flex items-center justify-center">
                            <span class='tooltip flex items-center justify-center rounded-button shadow-lg p-2 bg-gray-600 text-gray-200 -translate-y-full'>Étiquettes</span>
                            <ButtonVue class="relative m-1"
                            @click="traqr.getLabels()"
                            :label="null"
                            :filling="'outlined'"
                            :height="'medium'"
                            :icon="'TagIcon'"
                            :state="'neutral'"
                            />
                        </div>-->
                    </div>
                    <div class="flex flex-row">
                        <div class="has-tooltip flex items-center justify-center">
                            <span class='tooltip flex items-center justify-center rounded-button shadow-lg p-2 bg-gray-600 text-gray-200 -translate-y-full'>Partager</span>
                            <ButtonVue class="relative m-1"
                            @click="() => {
                                shareTraqr = true
                                //shareTraqrViaMailto()
                            }"
                            :label="null"
                            :filling="'filled'"
                            :height="'medium'"
                            :icon="'ArrowTopRightOnSquareIcon'"
                            :state="'neutral'"
                            :disabled="false"
                            />
                        </div>

                        <div class="has-tooltip flex items-center justify-center">
                            <span class='tooltip flex items-center justify-center rounded-button shadow-lg p-2 bg-gray-600 text-gray-200 -translate-y-full'>Détail</span>
                            <ButtonVue class="relative m-1"
                            @click="$emit('accessTraqr', traqr.id)"
                            :label="null"
                            :filling="'filled'"
                            :height="'medium'"
                            :icon="'ChevronRightIcon'"
                            :color="'primary'"
                            :disabled="false"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="shareTraqr"
        @closeModal="shareTraqr=false"
        :title="'Partager le TraQR '+traqr.getNonIluReferenceIfExists()"
        :confirmButtonLabel="'PARTAGER'"
        >
            <template v-slot:body>
                <div class="flex flex-col justify-center items-center msw-85% md:w-50%">
                    <SelectVue class="my-2 w-full"
                    v-model="shareType"
                    :placeholder="'Qualité du destinataire...'"
                    :height="'large'"
                    :state="'default'"
                    />
                    <AutocompleteVue class="my-2 w-full"
                    v-model="rawContact"
                    :placeholder="'Rechercher un contact...'"
                    :height="'large'"
                    :objects="contacts"
                    @input="searchContact()"
                    @setValue="setContact($event)"
                    />
                    <span class="my-2">OU</span>
                    <InputVue class="my-2 w-full"
                    v-model="email"
                    :placeholder="'Email'"
                    :height="'large'"
                    :state="'default'"
                    :enableValidation="true"
                    :type="'email'"
                    @validated="$event => {
                        emailValid = $event
                        }"
                    />
                </div>
            </template>
        </Modal>
    </div>
    <div v-else>
        <div 
        class="w-full flex flex-row border-b items-center justify-start" 
        @click="$emit('accessTraqr', traqr.id)"
        >
            <div class="pl-4 pb-4">
                <TagVue 
                :size="'large'"
                :alert="traqr.scans.length && traqr.scans[0].report ? getAlertType(traqr.scans[0].report.state.split('-')[0]) : null"
                :type="'traqr'"
                :state="traqr.state"
                />
            </div>
            <div class="flex flex-col items-start w-full">
                <div class="flex flex-col items-start justify-start">
                    <span class="text-body-4 xl:text-body-1 text-center">{{traqr.getNonIluReferenceIfExists()}}</span>
                </div>
                <div 
                class="flex flex-col items-start justify-start"
                v-if="traqr.getLastLocationCity()">
                    <div class="flex flex-row items-center justify-center">
                        <span class="text-body-3 text-gray-800">{{ traqr.getLastScanDate() ? "Dernière Position :" : "Adresse d'expédition :"}}</span>
                        <span class="text-body-5 text-gray-500 ml-2">{{ traqr.getLastScanDate() }}</span>
                        <span class="text-body-5 ml-1 text-gray-500">{{ traqr.getLastScanTime() }}</span>
                    </div>
                    <div class="flex flex-row items-center justify-center">
                        <div class="flex items-center justify-center">
                            <CountryFlag :country='traqr.getLastLocationCountry()' class="!m-0 rounded-checkbox shadow-sm"/>
                        </div>
                        <div class="flex flex-col items-start justify-center ml-2">
                            <span class="text-body-4 text-gray-500">{{traqr.getLastLocationStreet()}}</span>
                            <span class="text-body-3">{{traqr.getLastLocationCity()}}</span>
                        </div>
                    </div>
                </div>
                <div 
                class="flex flex-col items-start justify-start"
                v-else>
                    <div class="flex flex-row items-center justify-center">
                        <span class="text-body-3 text-gray-800">Destination : </span>
                    </div>
                    <div class="flex flex-row items-center justify-center">
                        <div class="flex items-center justify-center">
                            <CountryFlag :country='traqr.getConsigneeCountry()' class="!m-0 rounded-checkbox shadow-sm"/>
                        </div>
                        <div class="flex flex-col items-start justify-center ml-2">
                            <span class="text-body-4 text-gray-500">{{traqr.getConsigneeAddressStreet()}}</span>
                            <span class="text-body-3">{{traqr.getConsigneeAddressCity()}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AutocompleteVue from "@/components/elements/Autocomplete.vue"
import InputVue from "@/components/elements/Input.vue"
import SelectVue from "@/components/elements/Select.vue"
import ButtonVue from "@/components/elements/Button.vue"
import TagVue from "@/components/elements/Tag.vue"
import CountryFlag from 'vue-country-flag-next'
import Modal from "@/components/containers/Modal"

import Contact from '@/lib/entities/Contact'

export default {
    name: 'TraqrTableElement',
    components: {
        ButtonVue,
        TagVue,
        InputVue,
        AutocompleteVue,
        SelectVue,
        Modal,
        CountryFlag,
    },
    data(){
        return {
            shareType: null,
            shareTraqr: false,
            contact: new Contact(),
            contacts: [],
            rawContact: '',
            copy: true,
        }
    },
    emits: ["accessTraqr"],
    props:{
        traqr:{
            type: Object,
            default: null
        },
    },
    watch: {
        traqr: {
            handler() {},
            deep: true
        },
    },
    methods: {
        isMobile() {
            return this.$device === 'mobile' ||  this.$orientation === 'portrait'
        },
        async searchContact(){
            this.contacts = await this.contact.searchContact(this.rawContact)
            this.contacts.forEach((contact, index) => {
                this.contacts[index] = new Contact(contact)
                this.contacts[index].description = this.contacts[index].display() 
            })
        },
        async setContact(event){
            this.contact = event
            this.rawContact = this.contact.display()
            this.contacts = []
            //this.destination.update()
            //this.destination.loadingConstraintsComment = this.destinationLoadingConstraints
            //this.$emit('update:modelValue', this.destination)
        },
        getAlertType(string){
            switch (string){
                case 'validation': return 'success'
                case 'issue': return 'warning'
                case 'damage': return 'danger'
            }
        },
        shareTraqrViaMailto(){
            const link = document.createElement('a')
            link.href="mailto:?subject=[Numéro de Suivi] Expédition à destination de "+this.traqr.getConsigneeAddressCity()
            +"&body="+encodeURIComponent("Bonjour,\n\nJe vous prie de bien vouloir trouver ci-après le lien de suivi de votre expédition à destination de "+this.traqr.getConsigneeAddressCity()
            +".\n\nhttps://www.traq-king.com/?id="+this.traqr.id)
            //link.href = 'https://www.traq-king.com/?id='+this.traqr.id
            //link.download = response.data.location
            link.target = "_blank"
            link.click()
            URL.revokeObjectURL(link.href)
            return;
        },
        copyToClipboard(value){
            navigator.clipboard.writeText(value);
            this.copy = false
        },
    },
}
</script>