<template>
    <AutocompleteVue :key="reload"
    class="w-full"
    v-model="rawContact"
    :placeholder="'Rechercher un contact...'"
    :height="'large'"
    :objects="contacts"
    :state="setState"
    @input="searchContact()"
    @setValue="setContact($event)"
    />
</template>
    
    
    
<script>
import Contact from '@/lib/entities/Contact'
import AutocompleteVue from '@/components/elements/Autocomplete.vue'


export default {
    name: 'ContactAutocomplete',
    components:{
        AutocompleteVue
    },
    data(){
        return {
            contact: new Contact(),
            contacts: [],
            setState: 'warning',
            rawContact: '',
            reload: 0
        }
    },
    watch: {
        modelValue: {
          handler(){
            this.contact = this.modelValue 
            this.rawContact = this.contact ? this.contact.display() : null
            this.setState = 'success'
            this.rawContact = this.contact ? this.contact.display() : null
            this.$emit('update:modelValue', this.contact)
          }
        }
    },
    props:{
        modelValue: {
          type: Object,
        }
      },
    methods: {
        async searchContact(){
            this.contacts = await this.contact.searchContact(this.rawContact)
            this.contacts.forEach((contact, index) => {
                this.contacts[index] = new Contact(contact)
                this.contacts[index].description = this.contacts[index].display() 
            })
        },
        async setContact(event){
            this.contact = event
            this.rawContact = this.contact.display()
            this.contacts = []
            this.$emit('update:modelValue', this.contact)
        },    
    }
}
    </script>
    