<template>
    <div class="w-full flex flex-row bg-gray-100 overflow"
    :class="!isMobile()? 'flex-row' : 'flex-col-reverse'">
        <div class="flex flex-col h-[calc(100vh-100px)] overflow-y-scroll"
        :class="!isMobile()? 'w-66%' : 'w-full'">
            <span class="text-title-3 flex flex-row w-full items-center justify-center my-4">Renseigner l'une des deux catégories suivantes</span>
            <div class="p-5 bg-neutral-700 flex flex-row items-center justify-between mb-4 text-white select-none">
                <span class="text-title-3 no-select"> Marchandises </span>
            </div>
            <div class="w-full px-4">
                <TextVue
                v-model="traqr.goods"
                :placeholder="'Contenu de l\'expédition...'"
                :height="'large'"
                :state="'default'"
                />
            </div>
            <HandlingUnitTable
            v-model="traqr.handlingUnits"
            :title="'Unités de Manutention'"
            />
        </div>
        <div class="flex flex-col shadow-sm bg-gray-50 justify-between"
        :class="!isMobile()? 'w-34% py-4 h-[calc(100vh-100px)] overflow-y-scroll' : 'w-full'">
            <div class="fixed bottom-0 right-0 bg-gray-50 shadow-lg p-4 z-20 w-full lg:w-34%">
                <ButtonVue class="!w-full"
                :label="'Generer TraQR'"
                :filling="'filled'"
                :size="'large'"
                :state="createState"
                @click="traqrCreate"
                />
            </div>    
            <div class="flex flex-col w-full">
                <div class="w-full flex flex-row justify-start items-center px-4">
                    <div class="pb-4 mr-1">
                        <TagVue 
                        :size="'large'"
                        :type="'traqr'"
                        />
                    </div>
                    <InputVue class="w-full"
                    v-model="reference"
                    :placeholder="'Référence interne'"
                    :height="'large'"
                    :state="'default'"
                    />
                </div>
                <div class="w-full px-4 mb-2">
                    <TextVue
                    v-model="traqr.comment"
                    :placeholder="'Contraintes de transport...'"
                    :height="'medium'"
                    :state="'default'"
                    />
                </div>
                <div v-show="integrations" class="flex flex-col items-start w-full min-w-text-input-large mt-1">
                    <div class="flex flex-col w-full pt-2" @keypress="shipperAndConsigneeOpen = false">
                        <div class="p-5 bg-neutral-700 flex flex-row items-center justify-between mb-4 text-white select-none" @click="integrationsOpen = !integrationsOpen">
                            <span class="text-title-3 no-select"> Intégrations </span>
                            <component :is="integrationsOpen ? heroIcons['ChevronUpIcon'] : heroIcons['ChevronDownIcon']" class="stroke-1 h-7 w-7"/>
                        </div>
                        <div v-if="integrationsOpen">
                            <div v-for="(integration, index1) in integrations" :key="index1"
                            class="border-b p-4">
                                <component 
                                :key="integrationKey"
                                :is="integrationComponents[integration.display]"
                                :integrationId="integration.id"
                                @reinitTraqr="reinitTraqr()"
                                @destinationsValidated="($event) => {
                                    traqr = $event
                                    shipperAndConsigneeOpen = true
                                }"
                                @pushGetParameters="pushGetParameters = $event"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col items-start w-full min-w-text-input-large">
                    <div class="flex flex-col w-full" @keypress="integrationsOpen = false">
                        <div class="p-5 flex flex-row items-center justify-between mb-4 text-white select-none" @click="shipperAndConsigneeOpen = !shipperAndConsigneeOpen"
                        :class="consigneeDestinationBg">
                            <span class="text-title-3 no-select"> Destinataire </span>
                            <component :is="shipperAndConsigneeOpen ? heroIcons['ChevronUpIcon'] : heroIcons['ChevronDownIcon']" class="stroke-1 h-7 w-7"/>
                        </div>
                        <div v-show="shipperAndConsigneeOpen">
                            <div class="flex flex-col w-full px-4 mb-4">
                                <DestinationAutocompleteVue
                                v-model="traqr.consignedTo"
                                @update:modelValue="() => {
                                    createState = traqr.consignedTo.id ? 'default' : 'disabled'
                                    traqr.consigneeConstraints = traqr.consignedTo.loadingConstraints
                                    traqr.consigneeConstraintsComment = traqr.consignedTo.loadingConstraintsComment.length ? traqr.consignedTo.loadingConstraintsComment : null
                                }"
                                />
                                <div class="flex flex-row justify-between items-center my-2">
                                    <MultiSelectVue class="mr-2 w-full"
                                    v-model="traqr.consigneeConstraints"
                                    :options="options"
                                    :placeholder="'Contraintes à la livraison...'"
                                    :height="'large'"
                                    :state="'default'"/>
                                    <ButtonVue 
                                    @click="traqr.consigneeConstraintsComment = ''"
                                    :filling="'filled'"
                                    :label="null"
                                    :height="'large'"
                                    :icon="'PlusIcon'"
                                    :state="'success'"
                                    />
                                </div>
                                <TextVue
                                v-show="traqr.consigneeConstraintsComment !== null"
                                v-model="traqr.consigneeConstraintsComment"
                                :placeholder="'Informations complémentaires...'"
                                :height="'medium'"
                                :state="'default'"
                                />
                                <DatetimefieldVue
                                class="my-1"
                                v-model="traqr.shouldBeDeliveredAt"
                                :placeholder="'Date et heure de livraison...'"
                                :height="'large'"
                                :state="'default'"
                                :top="false"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col items-start w-full min-w-text-input-large">
                    <div class="flex flex-col w-full" @keypress="integrationsOpen = false">
                        <div class="p-5 bg-neutral-700 flex flex-row items-center justify-between mb-4 text-white select-none" @click="shipperAndConsigneeOpen = !shipperAndConsigneeOpen">
                            <span class="text-title-3 no-select"> Expéditeur </span>
                            <component :is="shipperAndConsigneeOpen ? heroIcons['ChevronUpIcon'] : heroIcons['ChevronDownIcon']" class="stroke-1 h-7 w-7"/>
                        </div>
                        <div v-show="shipperAndConsigneeOpen">
                            <div class="flex flex-col w-full px-4 mb-4">
                                <DestinationAutocompleteVue
                                v-model="traqr.shippedFrom"
                                @update:modelValue="() => {
                                    traqr.shipperConstraints = traqr.shippedFrom.loadingConstraints
                                    traqr.shipperConstraintsComment = traqr.shippedFrom.loadingConstraintsComment.length ? traqr.shippedFrom.loadingConstraintsComment : null
                                }"
                                />
                                <div class="flex flex-row justify-between items-center my-2">
                                    <MultiSelectVue class="w-full mr-2"
                                    v-model="traqr.shipperConstraints"
                                    :options="options"
                                    :placeholder="'Contraintes à l\'enlèvement...'"
                                    :height="'large'"
                                    :state="'default'"/>
                                    <ButtonVue 
                                    @click="traqr.shipperConstraintsComment = ''"
                                    :filling="'filled'"
                                    :label="null"
                                    :height="'large'"
                                    :icon="'PlusIcon'"
                                    :state="'success'"
                                    />
                                </div>
                                <TextVue v-show="traqr.shipperConstraintsComment !== null"
                                v-model="traqr.shipperConstraintsComment"
                                :placeholder="'Informations complémentaires...'"
                                :height="'medium'"
                                :state="'default'"
                                />
                                <DatetimefieldVue
                                class="my-1"
                                v-model="traqr.shouldBePickedUpAt"
                                :placeholder="'Date et heure d\'enlevement...'"
                                :height="'large'"
                                :state="'default'"
                                :top="true"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!isMobile()" class="h-[4rem]"></div>
            </div>
        </div>
        <LoadingModalVue v-if="traqrCreateLoading" @closeModal="traqrCreateLoading = false"/>
        <ModalVue v-if="confirmationModal"
        :title="'Confirmation création TraQR '+traqr.getReference('ILU')"
        @confirm="closeConfirmationModal()"
        @closeModal="closeConfirmationModal()">
            <template v-slot:body>
                <div class="w-full flex flex-col">
                    <div class="w-full flex flex-row border-b-2 border-grey-200 items-center justify-between pl-4">
                        <div class="flex flex-row items-center justify-start w-[70%] pr-6">
                            <div class="pl-4 pb-4">
                                <TagVue 
                                :size="'large'"
                                :alert="null"
                                :type="'handlingUnit'"
                                :status="null"
                                />
                            </div>
                            <div class="flex flex-col items-start justify-start w-full">
                                <span class="text-center"
                                :class="!isMobile() ? 'text-title-3' : 'text-body-1'">{{ traqr.getNonIluReferenceIfExists() }} </span>
                                <div class="has-tooltip flex items-center justify-start w-full">
                                    <span class='tooltip flex items-center justify-center rounded-button shadow-lg p-2 bg-gray-600 text-gray-200 translate-y-full'>{{ traqr.getTotalHandlingUnits() ? traqr.getTotalHandlingUnits() + ' unité(s) de manutention' : traqr.goods }}</span>
                                    <p class="text-gray-500 text-left truncate pointer-events-none w-full"
                                    :class="!isMobile() ? 'text-body-2' : 'text-body-3'">{{ traqr.getTotalHandlingUnits() ? traqr.getTotalHandlingUnits() + ' unité(s) de manutention' : traqr.goods }} </p>
                                </div>
                            </div>
                        </div>
                        <div class="mr-5 flex items-center justify-center">
                            <div class="mr-5 flex flex-row justify-center items-center">
                                <ButtonVue
                                class="mr-2"
                                @click="traqr.getDeliveryNote()"
                                :filling="'filled'"
                                :label="null"
                                :height="'large'"
                                :icon="'ClipboardDocumentListIcon'"
                                :state="'default'"
                                />
                                <!--<ButtonVue
                                @click="traqr.getLabels()"
                                :filling="'filled'"
                                :label="null"
                                :height="'large'"
                                :icon="'TagIcon'"
                                :state="'default'"
                                />-->
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col w-full justify-center items-center m-5 py-5">
                        <div class="text-title-2 mt-10 flex flex-row">
                            <span>Merci de faire confiance à</span> <img :src="traqKingLight" class="ml-2" width="160"/>
                        </div>
                        <div class="m-4 flex flex-row justify-center items-center w-full p-10 text-title-3">
                            <div v-if="traqr.shippedFrom.id !== null" class="flex flex-col">
                                <span>{{traqr.shippedFrom.address.addressLine1}}</span>
                                <span v-if="traqr.shippedFrom.address.addressLine2" >{{traqr.shipperDestination.address.addressLine2}}</span>
                                <span> {{ traqr.shippedFrom.address.zipcode}} </span>
                                <span> {{ traqr.shippedFrom.address.city }} </span>
                                <span>{{traqr.shippedFrom.address.country}}</span>
                            </div>
                            <component :is="heroIcons['ArrowRightIcon']" fill="black" class="stroke-1 h-10 w-10 mx-5"/>
                            <div v-if="traqr.consignedTo" class="flex flex-col">
                                <span>{{traqr.consignedTo.address.addressLine1}}</span>
                                <span v-if="traqr.consignedTo.address.addressLine2" >{{traqr.consigneeDestination.address.addressLine2}}</span>
                                <span> {{ traqr.consignedTo.address.zipcode}} </span>
                                <span> {{ traqr.consignedTo.address.city }} </span>
                                <span>{{traqr.consignedTo.address.country}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </ModalVue>
    </div>
</template>

<script>

import * as heroIcons from '@heroicons/vue/24/outline'


import DestinationAutocompleteVue from "@/components/groups/DestinationAutocomplete.vue"
import AutocompleteVue from "@/components/elements/Autocomplete.vue"
import HandlingUnitTable from "@/components/groups/HandlingUnitTable.vue"
import ButtonVue from "@/components/elements/Button.vue"
import DatetimefieldVue from "@/components/elements/Datetimefield.vue"
import TagVue from "@/components/elements/Tag.vue"
import InputVue from "@/components/elements/Input.vue"
import TextVue from "@/components/elements/Text.vue"
import RightSheetVue from "@/components/containers/RightSheet.vue"
import ModalVue from "@/components/containers/Modal.vue"
import ContactFormVue from "@/components/groups/ContactForm.vue"
import MultiSelectVue from '@/components/elements/MultiSelect.vue'
import LoadingModalVue from '@/components/elements/LoadingModal.vue'

import Traqr from "@/lib/entities/Traqr.js"
import { defineAsyncComponent, shallowRef } from 'vue'

export default ({
    name: 'TraqrCreateView',
    components:{
        AutocompleteVue,
        ButtonVue,
        HandlingUnitTable,
        DatetimefieldVue,
        TagVue,
        InputVue,
        TextVue,
        RightSheetVue,
        ModalVue,
        ContactFormVue,
        DestinationAutocompleteVue,
        MultiSelectVue,
        LoadingModalVue
    },
    data(){
        return {
            heroIcons: heroIcons,
            traqKingLight: require('../../assets/traq-king-light.svg'),

            confirmationModal: false,
            modalId: null,
            traqr: new Traqr(),

            transportConstraintsComment: null,
            
            shipperDestination: null,
            consigneeDestination: null,
            consigneeDestinationBg: 'bg-neutral-700',

            reference: null,

            createState: 'disabled',

            integrationComponents: [],
            integrations: null,
            integrationsOpen: true,
            shipperAndConsigneeOpen: true,

            pushGetParameters: null,
            integrationKey: 0,
            options: [
                { name: 'HAYON', code: 'HAYON' },
                { name: 'TAUT CÔTÉS', code: 'TAUTC' },
                { name: 'TAUT TOIT ET CÔTÉS', code: 'TAUTT&C' },
            ],
            traqrCreateLoading : false
        }
    },
    async created(){
        await this.retrieveAccountIntegrations()
    },
    methods:{
        isMobile() {
        return (this.$device === 'mobile' ||  this.$orientation === 'portrait') && window.innerWidth < 768
        },
        log(e){
            console.log(e)
        },
        updateModalValues(e){
            this.modalId = e
            this.modalOpen = true
        },
        reinitTraqr(){
            this.traqr = new Traqr()
        },
        async traqrCreate(){
            this.traqrCreateLoading = true
            if(!this.traqr.consignedTo.validate()) this.consigneeDestinationBg = 'bg-danger-700';
            if(!(this.traqr.handlingUnits.length || this.traqr.goods)) {
                this.traqr.handlingUnits.push({
                        "reference": "Référence " + (this.traqr.handlingUnits.length + 1),
                        "height": null,
                        "width": 80,
                        "length": 120,
                        "weight": null
                    })
            }
            if(this.reference) {
                this.traqr.customReferences = [
                    {
                        "reference" : this.reference,
                        "type" : "INTERNE"
                    }
                ]
            }
            if(this.traqr.validate()){
                await this.traqr.save(this.pushGetParameters)
                .then(() => this.traqr.id ? this.confirmationModal = true : window.location.replace('/traqrs_read'))
            }
            this.traqrCreateLoading = false
        },

        async retrieveAccountIntegrations(){
            await this.$axios
            .get(process.env.VUE_APP_API_BASE_PATH+'/settings?type=Integrations&identifier=integrations', { withCredentials: true })
            .then(response => {
                if(response.status == 200){
                    this.integrations = response.data['hydra:member'].length ? response.data['hydra:member'][0].value : null;
                    this.integrations !== undefined && this.integrations.length > 0 ? null : this.integrations = null;
                    this.integrations.forEach(element => {
                        this.integrationComponents[element.display] = shallowRef(defineAsyncComponent(() => import("@/components/groups/integrations/"+element.display)))
                    });
                }
            }).catch(() => {
                this.integrations = null
            })
        },

        closeConfirmationModal(){
            this.confirmationModal = false
            this.reinitTraqr()
            this.integrationKey++
        }
    },
    watch:{
        traqr:{
            handler(){
                //console.log(this.traqr)
            }
        }
    }
})
</script>