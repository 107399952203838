<template>
    <AutocompleteVue
    v-model="address.raw"
    :placeholder="'Adresse'"
    :height="'large'"
    :objects="addresses"
    :state="setState"
    @input="searchAddress()"
    @setValue="setAddress($event)"
    />
</template>
    
    
    
<script>
import Address from '@/lib/entities/Address'
import AutocompleteVue from '@/components/elements/Autocomplete.vue'

export default {
    name: 'AddressAutocomplete',
    components:{
        AutocompleteVue
    },
    data(){
        return {
            address: new Address({}),
            addresses: [],
            setState: 'warning'
        }
    },
    created(){
        this.setState = this.state == 'disabled' ? 'disabled' : 'warning'
        this.address.raw = this.inputAddress ? this.inputAddress.display() : null
    },  
    props: {
        state: {
            type: String,
            validator(value) {
                return ['default', 'success', 'danger', 'warning', 'disabled'].includes(value)
            }, 
            default: 'default'
        },
        inputAddress: {
            type: Address,
            default: null
        }
    },
    methods: {
        async searchAddress(){
            this.addresses = await this.address.searchAddress()
        },
        async setAddress(event){
            await this.address.setAddress(event)
            this.address.raw = event.description
            this.setState = 'success'
            this.$emit('address', this.address)
            this.addresses = []
        }
    
    }
}
    </script>