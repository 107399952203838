import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import axios from 'axios'
import * as heroIcons  from "@heroicons/vue/24/outline"
import { styles } from '@/lib/styles'
import './registerServiceWorker'

const app = createApp(App)

app.config.globalProperties.$global = app.config.globalProperties
app.config.globalProperties.$axios = axios
app.config.globalProperties.$heroIcons = heroIcons
app.config.globalProperties.$styles = styles
app.config.globalProperties.$connected = false
app.config.globalProperties.$me = null


app.use(router).mount('#app')

