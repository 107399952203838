<template lang="html">
    <div class="flex flex-col justify-start align-top">
        <span v-if="label" class="mb-1 mx-1 text-neutral-700" 
        :class="styling($styles.select.span_label)">
            {{label}}
        </span>
        <div class="flex relative">
            <select 
            v-model="dataValue"
            @change="$emit('update:modelValue', dataValue)" 
            class="rounded-text-input text-neutral-600 box-border border transition-all w-full outline-none focus:ring-1 focus:ring-inset focus:ring-offset-1 focus:border-primary-400 focus:ring-primary-400 shadow-sm hover:shadow-lg focus:shadow-xs appearance-none"
            :class="styling($styles.select.select)"
            >
                <option disabled value="null">{{ placeholder }}</option>
                <option 
                v-for="(option,key) in options" 
                :key="key" 
                :value="key">
                    {{ option.name }}
                </option>
            </select>
            <component :is="$heroIcons['ChevronDownIcon']" class="absolute pointer-events-none stroke-black h-6 w-6 top-[50%] right-0 -translate-y-2/4 -translate-x-1/4"/>
        </div>
        <span v-if="message" 
        class="mt-1 mx-1 inline-flex items-center" 
        :class="styling($styles.select.span_message)">
            <component
            :is="getMessageIcon" 
            fill="currentColor" 
            class="mr-1" 
            :class="styling($styles.select.icon_message)"/>
            {{message}}
        </span>
        <span v-if="helper" 
        class="mt-1 mx-1 text-neutral-400" 
        :class="styling($styles.select.span_helper)">
            {{helper}}
        </span>
    </div>
</template>
  
<script>

export default  {
    name: 'SelectVue',
    props: {
        height: {
            type: String,
            validator(value) {
                return ['large', 'medium', 'small'].includes(value)
            }, 
            default: 'medium'
        },
        placeholder: {
            type: String,
            default: 'Insert text here'
        },
        label: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
        helper: {
            type: String,
            default: ''
        },
        state: {
            type: String,
            validator(value) {
                return ['default', 'success', 'danger', 'warning', 'disabled'].includes(value)
            }, 
            default: 'default'
        },
        options: {
            type: Array, 
            default: () => {
                return []
            }
        },
        modelValue: {
            type: Number,
            default: 0
        }
    },
    watch:{
        modelValue: {
            handler() {
                this.dataValue = this.modelValue
            },
            deep: true
        },
        height: {
            handler() {},
            deep: true
        },
        placeholder: {
            handler() {},
            deep: true
        },
        label: {
            handler() {},
            deep: true
        },
        message: {
            handler() {},
            deep: true
        },
        helper: {
            handler() {},
            deep: true
        },
        state: {
            handler() {},
            deep: true
        },
        options: {
            handler() {},
            deep: true
        },
    },
    computed: {
        getMessageIcon() {
            return this.$heroIcons[this.$styles.select.iconMessage[this.state]];
        },
    },
    created(){
        this.dataValue = this.modelValue
    },
    data () {
        return {
            dataValue: null,
        }
    },
    methods: {
        getState(object){
            switch (this.state){
                case 'default':{
                    return object.default
                }
                case 'success':{
                    return object.success
                }
                case 'danger':{
                    return object.danger
                }
                case 'warning':{
                    return object.warning
                }
                case 'disabled':{
                    return object.disabled
                }
                default:{
                    return object.default
                }
            }
        },
        getHeight(object){
            switch (this.height){
                case 'large':{
                    return object.large
                }
                case 'medium':{
                    return object.medium
                }
                case 'small':{
                    return object.small
                }
                default:{
                    return object.medium
                }
            }
        },
        styling(object){
            var valren = ''
            object.height ? valren += this.getHeight(object.height) +' ' : null
            object.state ? valren += this.getState(object.state) +' ' : null
            return valren
        }
    },
  }
  
  
  </script>
  