<template class="font-lato">
  <div class="static" :key="reload">
    <NavbarVue v-if="$route.path!=='/'" ref="navbar" :firstName="firstName"/>
    <div class="relative"
    :class="isMobile() ? 'min-h-[calc(100vh-70px)] top-[70px]' : 'min-h-[calc(100vh-100px)] top-[100px]'"
    >
      <router-view class="font-lato mx-auto" 
      />
    </div>
  </div>
  <div v-show="connectionState == 'pending'">
      <div class="fixed top-0 left-0 z-20 h-full w-full bg-gray-500 opacity-50">
      </div>
      <div class="fixed p-10 top-[50%] left-[50%] -translate-x-2/4  -translate-y-2/4 z-50 bg-gray-50 rounded-modal shadow-xl flex flex-col justify-center items-center">
        <span class="m-2">Reconnexion...</span>  
        <LoadingVue/>
      </div>
  </div>
  <div v-show="connectionState == 'error'">
      <div class="fixed top-0 left-0 z-20 h-full w-full bg-gray-500 opacity-50">
      </div>
      <div class="fixed p-10 top-[50%] left-[50%] -translate-x-2/4  -translate-y-2/4 z-50 bg-gray-50 rounded-modal shadow-xl flex flex-col justify-center items-center">
        <span class="m-2">Une erreur est survenue...</span>  
        <ButtonVue
        @click="() => {
            if(retryCount < 3){
              tokenRefresh()
              retryCount++
            } else {
              goToLogin()
            }
          }"
        :label="retryCount < 3 ? 'Réessayer' : 'Se reconnecter'"
        :filling="'filled'"
        :height="'medium'"
        :icon="retryCount < 3 ? 'ArrowPathIcon' : 'ArrowRightIcon'"
        :color="'primary'"
        :disabled="false"
        />
      </div>
  </div>
  <div v-if="displayIFrame" 
  class="fixed p-10 top-[50%] left-[50%] -translate-x-2/4  -translate-y-2/4 z-50 bg-gray-50 rounded-modal shadow-xl flex flex-col justify-center items-center"
  :class="shaking">
    <LoginFormVue
    @loginFailed="warnDisabled()"
    @loginSuccess="() => {
        displayIFrame = false
        connectionState = 'success'
        reload++
        $forceUpdate()
      }"
    />
  </div>
</template>


<script>
import NavbarVue from '@/components/groups/Navbar.vue'
import LoginFormVue from '@/components/groups/LoginForm.vue'
import LoadingVue from '@/components/elements/Loading.vue'
import ButtonVue from '@/components/elements/Button.vue'

export default ({
  components: {
    NavbarVue,
    LoadingVue,
    ButtonVue,
    LoginFormVue
  },
  data(){
    return {
      reload: 1,
      connectionState: 'pending',
      firstName: null,
      retryCount: 0,
      displayIFrame: false,
    }
  },
  async beforeCreate(){
        await this.$axios
        .post(process.env.VUE_APP_API_BASE_PATH+'/token/refresh',{},{withCredentials: true})
        .then(async (response) => {
            if(response.status == 204){
                await this.$axios
                .get(process.env.VUE_APP_API_BASE_PATH+'/me',
                { 
                    withCredentials: true 
                }).then(response => {
                    if(response.status == 200){
                      this.$global.$me = response.data
                      this.firstName = response.data.firstName
                    } 
                })
            } 
            this.connectionState = 'success';
            this.$refs.navbar.setTab()
        }).catch((e) => {
            if(e.response.data.message === "Missing JWT Refresh Token"){
              this.goToLogin()
            } else {
              this.connectionState = 'error';
              this.reload++   
            }
            this.$refs.navbar.setTab()
        })
        setInterval(this.tokenRefresh, 80000000);
    },

    created(){
        this.getDeviceType()
        this.getOrientation()
        this.$nextTick(() => {
        window.addEventListener('resize', () => {
            var device = this.$device;
            this.getDeviceType()
            this.getOrientation()
            if(this.$device !== device){
                this.reload++
            }
        })})
    },

  methods:{
    isMobile() {
      return (this.$device === 'mobile' ||  this.$orientation === 'portrait') && window.innerWidth < 768
    },
    getDeviceType() {
      var device = null;
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        device = "tablet";
      } else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        device = "mobile";
      } else {
        device = "desktop";
      }
      this.$global.$device = device
    },
    getOrientation(){
      this.$global.$orientation = window.innerWidth / window.innerHeight > 1 ? 'landscape' : 'portrait'
    },
    async tokenRefresh(){
      this.connectionState = 'pending';
      await this.$axios
        .post(process.env.VUE_APP_API_BASE_PATH+'/token/refresh',{},{withCredentials: true})
        .then(() => {
            this.connectionState = 'success';
            this.retryCount = 0
        }).catch((e) => {
            if(e.response.data.message === "Missing JWT Refresh Token"){
              this.goToLogin()
            } else {
              this.connectionState = 'error';
              this.reload++   
            }
        })
    },
    goToLogin(){
      this.displayIFrame = true
    },
    warnDisabled() {
      this.shaking = 'shake'
      setTimeout(() => {
          this.shaking = null
      }, 500)
    }

  },
})
</script>

