<template lang="html">
    <div class="flex flex-row justify-start items-start h-[10rem] border-0 w-full border-l-2">
        <div class="h-40% w-5 border-b-2"></div>
        <div class="h-80% flex-col last:justify-between items-start w-full py-2">
          <div class="h-full flex flex-row justify-start items-center w-full">
              <div class="flex items-center justify-center" v-if="scan.getCountry()">
                  <CountryFlag :country='scan.getCountry()' class="!m-0 rounded-checkbox shadow-sm"/>
              </div>
              <div class="flex flex-col justify-center items-start ml-3">
                <div class="h-full flex flex-col justify-center items-start">
                    <span v-if="scan.hasAddress()" class="text-body-2 text-neutral-700">{{ scan.getAddress() ? scan.getAddress()+", " : "" }}{{ scan.getCity() }}</span>
                    <span class="text-body-2 text-gray-500">{{ scan.getLastScanTime() }} {{ scan.getLastScanDate() }}</span>
                </div>
                <div class="h-full flex flex-row justify-center items-center">
                    <span class="text-body-2 text-gray-500">{{ scan.getScannedEntity() }}</span>
                </div>
              </div>
          </div>
          <div class="px-4 h-20% w-full flex flex-row justify-start py-1"
          v-if="scan.report && scan.report.state.search('-created') < 0">
          
            <ButtonVue v-if="scan.report.state.substr(0,scan.report.state.search('-')) == 'validation'"
            class="w-34% mx-2"
            :label="'POD'"
            :filling="'outlined'"
            :height="'small'"
            :state="'success'"
            @click="openModal(scan.report.id, 'validation')"
            />
            <ButtonVue v-if="scan.report.state.substr(0,scan.report.state.search('-')) == 'issue'"
            class="w-34% mx-2"
            :label="'Probleme'"
            :filling="'outlined'"
            :height="'small'"
            :state="'warning'"
            @click="openModal(scan.report.id, 'issue')"
            />
            <ButtonVue v-if="scan.report.state.substr(0,scan.report.state.search('-')) == 'damage'"
            class="w-34% mx-2"
            :label="'Litige'"
            :filling="'outlined'"
            :height="'small'"
            :state="'danger'"
            @click="openModal(scan.report.id, 'damage')"
            />
          </div>
        </div>
    </div>
    <ModalVue v-if="modalOpen"
      :title="modalTitle"
      :confirmButtonLabel="'none'"
      @closeModal="modalOpen = false">
        <template v-slot:body>
          <LoadingVue v-if="loading"/>
          <div class="w-full" v-else>
            <div class="flex flex-row w-full justify-between p-6">
              <div class="flex flex-col items-start" v-if="report.commentShipper"
              :class="modalType === 'issue' ? 'w-full' : 'w-50%'"
              >
                <span class="text-body-1 xl:text-title-3 text-neutral-700">
                  Commentaire Transporteur
                </span>
                <span class="text-body-2 text-gray-600 mt-4">
                  {{ report.commentShipper }}
                </span>
              </div>
              <div class="flex flex-col w-50% items-end"  v-if="report.commentConsignee">
                <span class="text-body-1 xl:text-title-3 text-neutral-700">
                  Commentaire Destinataire
                </span>
                <span class="text-body-2 text-gray-500 mt-4">
                  {{ report.commentConsignee }}
                </span>
              </div>
            </div>
            <div class="w-full">
              <carousel :items-to-show="1">
                <slide v-for="(media, index) in report.medias" :key="index">
                  <div class="flex flex-col">
                    <component :is="getImageType(media.url)" :src="media.url" class="max-h-[50vh]" 
                    :width="getImageType(media.url) == 'iframe' ? '350' : null" 
                    :height="getImageType(media.url) == 'iframe' ? '500' : null"/>
                    <ButtonVue
                    class="mt-2"
                    :label="'Télécharger'"
                    :filling="'filled'"
                    :height="'small'"
                    :state="'neutral'"
                    @click="media.download()"
                    />
                  </div>
                </slide>
                <template #addons v-if="report.medias.length > 1">
                  <navigation/>
                  <pagination/>
                </template>
              </carousel>
            </div>
          </div>
        </template>
      </ModalVue>
  </template>
  
  <script lang="js">
  
    import * as heroIcons from '@heroicons/vue/24/outline'
    import CountryFlag from 'vue-country-flag-next'
    import Scan from '@/lib/entities/Scan'
    import Report from '@/lib/entities/Report'
    import ModalVue from "@/components/containers/Modal.vue"
    import ButtonVue from "@/components/elements/Button.vue"
    import LoadingVue from "@/components/elements/Loading.vue"
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
  
    export default  {
      name: 'TraqrScan',
      components:{
        CountryFlag,
        ModalVue,
        ButtonVue,
        LoadingVue,
        Carousel,
        Pagination,
        Navigation,
        Slide
      },
      props: {
        scan: {
          type: Scan,
        },
      },
      data () {
        return {
          heroIcons: heroIcons,
          modalOpen: false,
          modalType: false,
          modalTitle: "",
          loading: false,
          report: null,
        }
      },
      created(){
        //console.log(this.scan)
      },
      methods:{
        async openModal(id, type){
          this.modalOpen = true
          this.loading = true
          this.modalType = type
          switch(type){
            case 'damage':{
              this.modalTitle = "Litige"
              break;
            }
            case 'issue':{
              this.modalTitle = "Probleme"
              break;
            }
            case 'validation':{
              this.modalTitle = "Preuve de livraison"
              break;
            }
          }
          await this.$axios
          .get(process.env.VUE_APP_API_BASE_PATH+'/reports/' + id, {withCredentials: true})
          .then((response) => {
            this.report = new Report(response.data)

            this.loading = false
          })
        },
        getImageType(url){
          const extension = url.split(/[#?]/)[0].split('.').pop().trim();
          if(extension == 'pdf'){
            return 'iframe'
          } else {
            return 'img'
          }
        },
      },
      watch: {
        scan: {
          handler() {},
          deep: true
        },
      },
  }
  
  
  </script>
  