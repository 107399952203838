<template lang="html">
    <div class="flex flex-col justify-between items-center w-full h-full p-4 !pt-10">
        <div class="flex flex-col justify-start items-center w-full">
            <span class="text-title-2 mb-10">
                {{inputContact ? 'Modifier' : 'Créer'}} un contact
            </span>
            <InputVue class="my-2 w-full"
            v-model="contact.companyName"
            :placeholder="'Entreprise'"
            :height="'large'"
            :state="'default'"
            />
            <InputVue class="my-2 w-full"
            v-model="contact.lastName"
            :placeholder="'Nom'"
            :height="'large'"
            :state="'default'"
            />
            <InputVue class="my-2 w-full"
            v-model="contact.firstName"
            :placeholder="'Prénom'"
            :height="'large'"
            :state="'default'"
            />
            <InputVue class="my-2 w-full"
            v-model="contact.email"
            :placeholder="'Email'"
            :height="'large'"
            :state="'default'"
            :enableValidation="true"
            :type="'email'"
            @validated="$event => {
                emailValid = $event
                }"
            />
            <PhoneInputVue class="my-2 w-full"
            v-model="contact.phone"
            :placeholder="'Téléphone'"
            :height="'large'"
            @validated="$event => {
                phoneValid = $event
                }"
            />
        </div>
        <ButtonVue 
        class="w-full"
        :filling="'filled'"
        :label="'Enregistrer dans mes contacts'"
        :height="'large'"
        :icon="'PlusIcon'"
        :state="'default'"
        @click="() => {
            if(
                phoneValid == null || phoneValid
                && emailValid == null || emailValid
            ){
                contact.id ? contact.update() : contact.save()
                this.$emit('contact' , contact)
            }
        }"
        />
    </div>
</template>


  
<script lang="js">

import ButtonVue from '@/components/elements/Button.vue'
import InputVue from '@/components/elements/Input.vue'
import PhoneInputVue from '@/components/elements/PhoneInput.vue'


import Contact from '@/lib/entities/Contact'

export default  {
    name: 'ContactForm',
    components:{
    ButtonVue,
    InputVue,
    PhoneInputVue
    },
    props:{
        inputContact:{
            type: Object,
            default: null
        },
    },
    data () {
        return {
            contact: null,
            emailValid: null,
            phoneValid: null,
        }
    },
    created(){
        this.contact =  new Contact()
        if(this.inputContact){
            for (let key in this.inputContact) {
                this.contact[key] = this.inputContact[key];
            }
        }
    },
    methods:{
        log(e){
            console.log(e)
        },
    },
}


</script>
  