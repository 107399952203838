<template lang="html">
    <div class="flex flex-row shadow-sm">
        <ul class="inline-flex items-center -space-x-px">
            <li class="border border-gray-400 rounded-l-checkbox p-1 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300" @click="$emit('previousPage')">
                <component :is="$heroIcons['ChevronLeftIcon']" fill="none" class="stroke-1 h-7 w-7" />
            </li>
            <li class="border border-gray-400 p-1 text-body-2 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300">
                <div class="h-7 min-w-9 px-1 flex items-center justify-center">{{ pageNumber }} / {{ pageTotal }}</div>
            </li>
            <li class="border border-gray-400 rounded-r-checkbox p-1 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300" @click="$emit('nextPage')">
                <component :is="$heroIcons['ChevronRightIcon']" fill="none" class="stroke-1 h-7 w-7" />
            </li>
        </ul>
    </div>  
  </template>
  
  <script lang="js">
  export default  {
      name: 'PaginatorVue',
      props: {
        pageNumber: {
            type: Number,
            default: 1
        },
        pageTotal: {
            type: Number,
            default: 1
        },
      },
      watch: {
        pageNumber:{
            handler(){},
            deep: true
        },
        pageTotal:{
            handler(){},
            deep: true
        }
      },
  }
  
  
  </script>
  