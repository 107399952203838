<template>
    <div class="flex flex-row w-full relative">
        <TraqrReference
            class="fixed flex flex-row border-b items-center justify-between px-5 z-10"
            :class="isMobile() ? 'w-full' : 'w-85%'"
            :pageNumber="pageNumber"
            :pageTotal="pageTotal"
            v-model="reference"
            @nextPage="nextPage()"
            @previousPage="previousPage()"
            @update:modelValue="() => {
                udpatefilterParameters('reference')
                traqrsRead()
            }"
            @openFilters="openFilters($event)"
        />
        <div 
        class="flex flex-col absolute overflow-y-scroll"
        :class="isMobile() ? 'w-full top-[calc(6.5rem)] ' :  'w-85% top-[calc(4rem)] '
                //filtersOpen ? valren += 'overflow-y-none ' : null
                "
        >
            <LoadingVue v-if="loading"
            class="fixed left-[50%] top-[50%] -translate-x-2/4 -translate-y-2/4"/>
            <div v-else-if="traqrs.length"
            >
                <TraqrTableElement
                class="w-full flex flex-row border-b items-center justify-start" 
                v-for="(object, index) in traqrs" 
                :key="index"
                :traqr="object"
                @accessTraqr="$event => accessTraqr($event)"
                />
            </div>
            <div v-else-if="!traqrs.length"
            class="fixed left-[50%] top-[50%] -translate-x-2/4 -translate-y-2/4">
                Aucun TraQR
            </div>
        </div>
        <component
        :is="isMobile() ? 'BottomSheet' : 'div'"
        v-if="isMobile() ? filtersOpen : true"
        @closeModal="filtersOpen = false"
        >  
            <template v-if="isMobile()" v-slot:body>
                <div class="overflow-y-scroll w-full p-4">
                    <ButtonVue
                    class="w-full"
                    :filling="'filled'"
                    :label="totalItems + ' résultat' + (totalItems > 1 ? 's' : '')"
                    :height="'medium'"
                    :state="'neutral'"
                    :icon="deleteFilters ? 'XMarkIcon' : null"
                    @click="() => {
                        filterParameters = {}
                        filterKey++
                        deleteFilters = false
                    }"
                    />
                    <FiltersSection v-model="filterParameters"/>
                </div>
            </template>
            <div v-if="!isMobile()"
            class="fixed bg-gray-100 border-l h-full w-15% top-[100px] right-0 p-4 overflow-y-scroll">
                <ButtonVue
                class="w-full"
                :filling="'filled'"
                :label="totalItems + ' résultat' + (totalItems > 1 ? 's' : '')"
                :height="'medium'"
                :state="deleteFilters ? 'neutral' : 'disabled'"
                :icon="deleteFilters ? 'XMarkIcon' : null"
                @click="() => {
                    filterParameters = {}
                    filterKey++
                    deleteFilters = false
                }"
                />
                <FiltersSection v-model="filterParameters" :key="filterKey"/>
            </div>
        </component>

        <RightSheet v-if="traqrDetails"
        @closeModal="closeTraqrDetails()">
            <template v-slot:body>
                <div v-if="traqr" class="flex flex-col justify-start items-start w-full">
                    <div class="w-full flex flex-row justify-between border-b-2 items-center">
                        <div class="w-[80%] flex flex-row border-grey-200 items-center justify-start h-[6rem]">
                            <div class="ml-4 mb-4">
                                <TagVue 
                                :size="'large'"
                                :alert="null"
                                :type="'handlingUnit'"
                                :state="null"
                                />
                            </div>
                            <div class="w-full flex flex-col items-start justify-start pr-6">
                                <span class="text-center"
                                :class="!isMobile() ? 'text-title-3' : 'text-body-1'">{{ traqr.getNonIluReferenceIfExists() }} </span>
                                <div class="has-tooltip flex items-center justify-center w-full">
                                    <span class='tooltip flex items-center justify-center rounded-button shadow-lg p-2 bg-gray-600 text-gray-200 translate-y-full'>{{ traqr.getTotalHandlingUnits() ? traqr.getTotalHandlingUnits() + ' unité(s) de manutention' : traqr.goods }}</span>
                                    <p class="text-gray-500 text-left truncate w-full pointer-events-none"
                                    :class="!isMobile() ? 'text-body-2' : 'text-body-3'">{{ traqr.getTotalHandlingUnits() ? traqr.getTotalHandlingUnits() + ' unité(s) de manutention' : traqr.goods }} </p>
                                </div>
                            </div>

                        </div>
                        <ButtonVue v-if="!isMobile()"
                        class="mr-8"
                        :filling="'outlined'"
                        :label="null"
                        :height="'medium'"
                        :icon="'PencilSquareIcon'"
                        :state="'neutral'"
                        :disabled="false"
                        @click="openEditTraqr()"
                        />
                    </div>
                    <div class="w-full flex flex-col items-start justify-start pl-4 overflow-y-scroll"
                    :class="!isMobile() ? 'h-[calc(100vh-100px-6rem)]' : 'h-[calc(100vh-70px-6rem)]'">
                        <!--<TraqrAddStep v-if="!traqr.delivered"/>-->
                        <TraqrScan v-for="(scan, index) in traqr.scans" :key="index" :scan="scan"/>
                        <div v-if="!traqr.scans.length" class="text-gray-500 text-body-2 flex flex-col justify-center items-center w-full h-full">Aucune activité jusqu'à présent</div>
                    </div>
                </div>
                <div v-else class="flex flex-col h-full justify-center items-center w-full">
                    <LoadingVue/>
                </div>
            </template>
        </RightSheet>
    </div>
</template>

<script>
import Traqr from "../lib/entities/Traqr"

import TraqrTableElement from "@/components/groups/TraqrTableElement.vue"
import TraqrReference from "@/components/groups/TraqrReference.vue"
import BottomSheet from "@/components/containers/BottomSheet.vue"
import RightSheet from "@/components/containers/RightSheet.vue"
import LoadingVue from "@/components/elements/Loading.vue"
import TagVue from "@/components/elements/Tag.vue"
import ButtonVue from "@/components/elements/Button.vue"
import TraqrScan from "@/components/groups/TraqrScan.vue"
import TraqrAddStep from "@/components/groups/TraqrAddStep.vue"
import FiltersSection from "@/components/groups/FiltersSection.vue"

export default ({
    name: 'TraqrsReadView',
    components:{
        TraqrTableElement,
        TraqrReference,
        BottomSheet,
        LoadingVue,
        FiltersSection,
        RightSheet,
        TagVue,
        TraqrScan,
        TraqrAddStep,
        ButtonVue,
    },
    data(){
        return {
            loading: true,
            traqrs: null,
            query: null,
            reference: null, 
            pageNumber: 1,
            pageTotal: 1,
            totalItems: 0,
            filtersOpen: false,
            traqrDetails: false,
            filterParameters: {},
            traqr: null,
            editTraqr: null,
            deleteFilters: false,
            filterKey: 0
        }
    },
    mounted(){
        this.traqrsRead()
    },
    methods:{
        log(a){console.log(a)},
        accessTraqr(value){
            this.traqrDetails = true
            this.traqrRead(value)
        },
        isMobile() {
        return (this.$device === 'mobile' ||  this.$orientation === 'portrait') && window.innerWidth < 768
        },
        updateReference(event){
            this.reference = event
            this.udpatefilterParameters()
        },
        openFilters(){
            this.filtersOpen = true
        },
        async nextPage(){
            if(this.pageNumber < this.pageTotal){
                this.pageNumber = this.pageNumber+1
                this.udpatefilterParameters("page")
                this.traqrsRead()
            }
        },
        async previousPage(){
            if(this.pageNumber > 1){
                this.pageNumber = this.pageNumber-1
                this.udpatefilterParameters("page")
                this.traqrsRead()
            }
        },

        udpatefilterParameters(modification = "filters"){
            this.query = process.env.VUE_APP_API_BASE_PATH+'/traqrs'
            var getParameters = modification == "filters" ? "?page=1" : getParameters = "?page=" + this.pageNumber
            getParameters += this.reference ? "&reference="+this.reference : ""
            var dateType = ""
            var addressType = ""
            var filterParameters = ""
            if(Object.keys(this.filterParameters).length > 0){
                filterParameters += this.filterParameters.contact && this.filterParameters.contact.id && this.filterParameters.contactShipper ? `&shippedFrom.contact.id=${this.filterParameters.contact.id}` : ''
                filterParameters += this.filterParameters.contact && this.filterParameters.contact.id && this.filterParameters.contactConsignee ? `&consignedTo.contact.id=${this.filterParameters.contact.id}` : ''
                this.filterParameters.dateType && this.filterParameters.dateType > 0 ?  dateType = "updatable.createdAt"  : dateType = "updatable.lastUpdatedAt" 
                this.filterParameters.dateOrder && this.filterParameters.dateOrder > 0 ? filterParameters = filterParameters+ "&order["+dateType+"]=asc" : filterParameters = filterParameters+ "&order["+dateType+"]=desc"
                this.filterParameters.dateBefore ? filterParameters = filterParameters+ "&"+dateType+"[before]="+ new Date(this.filterParameters.dateBefore.split('/')[2], this.filterParameters.dateBefore.split('/')[1] - 1, this.filterParameters.dateBefore.split('/')[0]).toISOString() : null
                this.filterParameters.dateAfter ? filterParameters = filterParameters+ "&"+dateType+"[after]=" + new Date(this.filterParameters.dateAfter.split('/')[2], this.filterParameters.dateAfter.split('/')[1] - 1, this.filterParameters.dateAfter.split('/')[0]).toISOString() : null
                if(this.filterParameters.awaiting || this.filterParameters.transit ||  this.filterParameters.delivered){
                    filterParameters = filterParameters+ "&state="
                    var workArray = []
                    this.filterParameters.awaiting ? workArray.push("awaiting") : null
                    this.filterParameters.transit ?  workArray.push("transit") : null
                    this.filterParameters.delivered ?   workArray.push("delivered") : null
                    workArray.forEach((value,index) => {
                        filterParameters += index ? ';'+value : value
                    })
                }
                switch(this.filterParameters.addressType){
                    case 0:{
                        addressType = 'consignedTo'
                        break;
                    }
                    case 1:{
                        addressType = 'shippedFrom'
                        break;
                    }
                    default:{
                        addressType = 'consignedTo'
                        break;
                    }
                }
                this.filterParameters.city && this.filterParameters.city.length > 0 ? filterParameters = filterParameters+ "&"+addressType+".address.city="+ this.filterParameters.city : null
                this.filterParameters.country && this.filterParameters.country.length > 0 ? filterParameters = filterParameters+ "&"+addressType+".address.country=" + this.filterParameters.country : null
                this.filterParameters.zipcode && this.filterParameters.zipcode.length > 0 ? filterParameters = filterParameters+ "&"+addressType+".address.zipcode="+ this.filterParameters.zipcode : null
                this.filterParameters.address && this.filterParameters.address.length > 0 ? filterParameters = filterParameters+ "&"+addressType+".address.addressLine1=" + this.filterParameters.address : null
                if(filterParameters.length){
                    this.deleteFilters = true;
                    getParameters += filterParameters;
                }

            }
            this.query = this.query+getParameters
        },

        openEditTraqr(){
            this.editTraqr = true
        },

        closeTraqrDetails(){
            this.traqrDetails = false
            this.traqr = null
        },

        async traqrsRead(){
            this.loading = true
            this.query ? null : this.query = process.env.VUE_APP_API_BASE_PATH+'/traqrs'
            await this.$axios
            .get(this.query, {withCredentials: true})
            .then(response => {
                if(response.status == 200){
                    this.loading = false
                    this.totalItems = response.data['hydra:totalItems']
                    this.traqrs = []
                    response.data['hydra:member'].forEach((traqr) => {
                        this.traqrs.push(new Traqr(traqr))
                    })
                    if(response.data['hydra:view']){
                        this.pageNumber = parseInt((JSON.parse('{"' + decodeURI(response.data['hydra:view']["@id"].split('?')[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}'))["page"])
                        this.pageNumber = !this.pageNumber ? 1 : this.pageNumber
                        response.data['hydra:view']["hydra:last"] ? this.pageTotal = parseInt((JSON.parse('{"' + decodeURI(response.data['hydra:view']["hydra:last"].split('?')[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}'))["page"]) : this.pageTotal = this.pageNumber
                    }
                }
            })
        },
        async traqrRead(id){
            await this.$global.$axios
            .get(process.env.VUE_APP_API_BASE_PATH+'/traqrs/'+id, {  withCredentials: true })
            .then(response => {
                if(response.status == 200){
                    this.traqr = new Traqr(response.data)
                }
            })
        },
    },
    watch: {
        filterParameters:{
            handler() {
                this.udpatefilterParameters()
                this.traqrsRead()
            },
            deep: true
        }
    }
})
</script>