<template lang="html">
    <div class="flex flex-row justify-start items-start h-[7rem] border-0 w-full">
        <div class="h-full w-0 border-l-2"></div>
        <div class="h-50% w-5 border-b-2"></div>
        <div class="h-full flex flex-row justify-start items-center w-full px-4">
            <div class="flex flex-col justify-center items-start w-full mr-2">
                <AddressAutocomplete
                class="w-full"
                :placeholder="'Prochaine étape'"
                @address="log($event)"
                :height="'medium'"
                :state="'default'"
                :disabled="false"
                />
                <DatetimefieldVue class="w-full mt-2"
                :placeholder="'Date de livraison prévue'"
                :height="'medium'"
                v-model="dateTime"
                @update:modelValue="log(dateTime)"
                />

            </div>
            <ButtonVue
            :filling="'outlined'"
            :label="null"
            :height="'medium'"
            :icon="'PlusIcon'"
            :state="'success'"
            :disabled="false"
            @click="$emit('updateReference')"
            />
        </div>
    </div>
    
  </template>
  
  <script lang="js">
  
    import * as heroIcons from '@heroicons/vue/24/outline'
    //import CountryFlag from 'vue-country-flag-next'
    import Traqr from '@/lib/entities/Traqr'
    //import NextStep from '@/lib/entities/NextStep'
    import ButtonVue from '@/components/elements/Button'
    import AddressAutocomplete from '@/components/groups/AddressAutocomplete'
    import DatetimefieldVue from '@/components/elements/Datetimefield'
  
    export default  {
      name: 'TraqrAddStep',
      components:{
        //CountryFlag,
        ButtonVue,
        AddressAutocomplete,
        DatetimefieldVue
      },
      props: {
        traqr: {
          type: Traqr,
        },
      },
      data () {
        return {
          heroIcons: heroIcons,
          dateTime: null,

        }
      },
      watch: {
        traqr: {
          handler() {},
          deep: true
        },
      },
      methods:{
        log(e){
            console.log(e)
        }
      },
  }
  
  
  </script>
  