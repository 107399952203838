<template lang="html">
     <div class="flex flex-col justify-between items-center w-full h-full p-4 !pt-10">
        <div class="flex flex-col justify-start items-center w-full">
            <span class="text-title-2 mb-10">
                {{inputDestination ? 'Modifier' : 'Ajouter'}} une destination
            </span>
            <AddressAutocomplete
            class="my-2 w-full"
            :inputAddress="destination.address"
            :state="destination.id ? 'disabled' : 'default'"
            @address="$event => destination.address = $event"
            />
            <InputVue class="my-2 w-full"
            v-model="destination.name"
            :placeholder="'Designation'"
            :height="'large'"
            :state="'default'"
            />
            <MultiSelectVue class="my-2 w-full"
            v-model="destination.loadingConstraints"
            :options="options"
            :placeholder="'Qualité du destinataire...'"
            :height="'large'"
            :state="'default'"/>
            <TextVue class="my-2 w-full"
            v-model="destination.loadingConstraintsComment"
            :placeholder="'Commentaires'"
            :height="'large'"
            :state="'default'"
            />
        </div>
        <ButtonVue 
        class="w-full"
        :filling="'filled'"
        :label="'Ajouter destination'"
        :height="'large'"
        :icon="'PlusIcon'"
        :state="'default'"
        @click="() => {
            destination.id ? destination.update() : destination.save()
            this.$emit('destination' , destination)
        }"
        />
    </div>
</template>


  
<script lang="js">

/**
 *  //:placeholder="'Contraintes de chargements'"
            :height="'large'"
            :state="'default'"
 */

import ButtonVue from '@/components/elements/Button.vue'
import InputVue from '@/components/elements/Input.vue'
import TextVue from '@/components/elements/Text.vue'

import MultiSelectVue from '@/components/elements/MultiSelect.vue'
import AddressAutocomplete from '@/components/groups/AddressAutocomplete.vue'

import Destination from '@/lib/entities/Destination'

export default  {
    name: 'DestinationForm',
    components:{
        AddressAutocomplete,
        ButtonVue,
        InputVue,
        TextVue,
        MultiSelectVue
    },
    data () {
        return {
            destination: new Destination(),
            options: [
                { name: 'Vue.js', code: 'Vue' },
                { name: 'Adonis', code: 'Ado' },
                { name: 'Rails', code: 'Rai' },
                { name: 'Sinatra', code: 'Sin' },
                { name: 'Laravel', code: 'Lar' },
                { name: 'Phoenix', code: 'Pho' },
                { name: 'Symphone', code: 'Syp' }
            ]
        }
    },
    props:{
        inputContact:{
            type: Object,
            default: null
        },
        inputDestination:{
            type: Object,
            default: null
        },
    },
    created() {
        this.destination = this.inputDestination ? this.inputDestination : new Destination()
        this.destination.contact = this.inputContact ? this.inputContact : null
    },
    methods:{
        log(e){
            console.log(e)
        },
        addTag (newTag) {
        const tag = {
            name: newTag,
            code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
        }
        this.options.push(tag)
        this.loadingConstraints.push(tag)
        }
    },
}


</script>
  