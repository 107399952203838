<template>
    <div>
        <div class="fixed top-0 left-0 z-20 h-full w-full bg-gray-500 opacity-50">
        </div>
        <div class="fixed p-10 top-[50%] left-[50%] -translate-x-2/4  -translate-y-2/4 z-50 bg-gray-50 rounded-modal shadow-xl flex flex-col justify-center items-center">
            <LoadingVue/>
        </div>
    </div>
  </template>
  
  <script>
  
  import LoadingVue from "./Loading.vue"
  
  export default {
    name: 'LoadingModalVue',
    components: {
     LoadingVue
    },
  }
  </script>
  
  