<template lang="html">
    <div class="flex flex-row items-center">
      <div class="relative mt-2 mr-2">
        <input
        type="checkbox"
        class="appearance-none border border-neutral-500 form-checkbox checked:bg-primary-400 rounded-checkbox"
        :class="styling($styles.checkbox.input)"
        :disabled="state === 'disabled'"
        v-model="dataValue"
        @change="$emit('update:modelValue', dataValue)" 
        />
        <component v-if="dataValue" 
        :is="$heroIcons['CheckIcon']" 
        class="absolute pointer-events-none stroke-white" 
        :class="styling($styles.checkbox.icon_check)"
        />
      </div>
      <span>{{ label }}</span>
    </div>
  </template>
  
  <script lang="js">

  
    export default  {
        name: 'CheckboxVue',
        props: {
            modelValue: {
                type: Boolean,
                default: false
            },
            height: {
                type: String,
                validator(value) {
                    return ['large', 'medium', 'small'].includes(value)
                }, 
                default: 'medium'
            },
            label: {
                type: String,
                default: ''
            },
            state: {
                type: String,
                validator(value) {
                    return ['default', 'disabled'].includes(value)
                }, 
                default: 'default'
            },
        },
        watch: {
            modelValue: {
                handler() {
                    this.dataValue = this.modelValue
                },
                deep: true
            },
            height: {
            handler() {},
            deep: true
            },
            label: {
            handler() {},
            deep: true
            },
            state: {
            handler() {},
            deep: true
            },
        },
        data () {
            return {
                dataValue: '',
            }
        },
        created(){
            this.dataValue = this.modelValue
        },
        methods: {
            getHeight(object){
                switch (this.height){
                    case 'large':{
                        return object.large
                    }
                    case 'medium':{
                        return object.medium
                    }
                    case 'small':{
                        return object.small
                    }
                    default:{
                        return object.medium
                    }
                }
            },
            styling(object){
                var valren = ''
                object.height ? valren += this.getHeight(object.height) +' ' : null
                return valren
            }
        },
  }
  
  
  </script>
  