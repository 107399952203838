<template>
    <div class="flex flex-col h-[calc(100vh-70px)] justify-center items-start px-10">
        <span class="text-title-1 my-10">404 Not Found</span>
        <span class="text-body-2 my-2">Vous allez être redirigé vers la page d'acceuil</span>
    </div>
</template>

<script>

export default ({
    name: 'PageNotFoundView',
    created(){
        this.$emit('loaded')
        setTimeout(() => {
            console.log(this.$me)
            if(this.$me){
                this.$router.replace('/traqrs_read')
            } else {
                this.$router.replace('/')
            }
        }, 5000)
    }
})
</script>