export const phoneCodes = [
    {
       "name":"🇨🇦 +1",
       "code":"CA",
       "dial_code":"+1"
    },
    {
       "name":"🇺🇸 +1",
       "code":"US",
       "dial_code":"+1"
    },
    {
       "name":"🇷🇺 +7",
       "code":"RU",
       "dial_code":"+7"
    },
    {
       "name":"🇪🇬 +20",
       "code":"EG",
       "dial_code":"+20"
    },
    {
       "name":"🇿🇦 +27",
       "code":"ZA",
       "dial_code":"+27"
    },
    {
       "name":"🇬🇷 +30",
       "code":"GR",
       "dial_code":"+30"
    },
    {
       "name":"🇳🇱 +31",
       "code":"NL",
       "dial_code":"+31"
    },
    {
       "name":"🇧🇪 +32",
       "code":"BE",
       "dial_code":"+32"
    },
    {
       "name":"🇫🇷 +33",
       "code":"FR",
       "dial_code":"+33"
    },
    {
       "name":"🇪🇸 +34",
       "code":"ES",
       "dial_code":"+34"
    },
    {
       "name":"🇭🇺 +36",
       "code":"HU",
       "dial_code":"+36"
    },
    {
       "name":"🇮🇹 +39",
       "code":"IT",
       "dial_code":"+39"
    },
    {
       "name":"🇷🇴 +40",
       "code":"RO",
       "dial_code":"+40"
    },
    {
       "name":"🇨🇭 +41",
       "code":"CH",
       "dial_code":"+41"
    },
    {
       "name":"🇦🇹 +43",
       "code":"AT",
       "dial_code":"+43"
    },
    {
       "name":"🇬🇬 +44",
       "code":"GG",
       "dial_code":"+44"
    },
    {
       "name":"🇮🇲 +44",
       "code":"IM",
       "dial_code":"+44"
    },
    {
       "name":"🇯🇪 +44",
       "code":"JE",
       "dial_code":"+44"
    },
    {
       "name":"🇬🇧 +44",
       "code":"GB",
       "dial_code":"+44"
    },
    {
       "name":"🇩🇰 +45",
       "code":"DK",
       "dial_code":"+45"
    },
    {
       "name":"🇸🇪 +46",
       "code":"SE",
       "dial_code":"+46"
    },
    {
       "name":"🇳🇴 +47",
       "code":"NO",
       "dial_code":"+47"
    },
    {
       "name":"🇵🇱 +48",
       "code":"PL",
       "dial_code":"+48"
    },
    {
       "name":"🇩🇪 +49",
       "code":"DE",
       "dial_code":"+49"
    },
    {
       "name":"🇵🇪 +51",
       "code":"PE",
       "dial_code":"+51"
    },
    {
       "name":"🇲🇽 +52",
       "code":"MX",
       "dial_code":"+52"
    },
    {
       "name":"🇨🇺 +53",
       "code":"CU",
       "dial_code":"+53"
    },
    {
       "name":"🇦🇷 +54",
       "code":"AR",
       "dial_code":"+54"
    },
    {
       "name":"🇧🇷 +55",
       "code":"BR",
       "dial_code":"+55"
    },
    {
       "name":"🇨🇱 +56",
       "code":"CL",
       "dial_code":"+56"
    },
    {
       "name":"🇨🇴 +57",
       "code":"CO",
       "dial_code":"+57"
    },
    {
       "name":"🇻🇪 +58",
       "code":"VE",
       "dial_code":"+58"
    },
    {
       "name":"🇲🇾 +60",
       "code":"MY",
       "dial_code":"+60"
    },
    {
       "name":"🇦🇺 +61",
       "code":"AU",
       "dial_code":"+61"
    },
    {
       "name":"🇨🇽 +61",
       "code":"CX",
       "dial_code":"+61"
    },
    {
       "name":"🇨🇨 +61",
       "code":"CC",
       "dial_code":"+61"
    },
    {
       "name":"🇮🇩 +62",
       "code":"ID",
       "dial_code":"+62"
    },
    {
       "name":"🇵🇭 +63",
       "code":"PH",
       "dial_code":"+63"
    },
    {
       "name":"🇳🇿 +64",
       "code":"NZ",
       "dial_code":"+64"
    },
    {
       "name":"🇸🇬 +65",
       "code":"SG",
       "dial_code":"+65"
    },
    {
       "name":"🇹🇭 +66",
       "code":"TH",
       "dial_code":"+66"
    },
    {
       "name":"🇰🇿 +77",
       "code":"KZ",
       "dial_code":"+77"
    },
    {
       "name":"🇯🇵 +81",
       "code":"JP",
       "dial_code":"+81"
    },
    {
       "name":"🇰🇷 +82",
       "code":"KR",
       "dial_code":"+82"
    },
    {
       "name":"🇻🇳 +84",
       "code":"VN",
       "dial_code":"+84"
    },
    {
       "name":"🇨🇳 +86",
       "code":"CN",
       "dial_code":"+86"
    },
    {
       "name":"🇹🇷 +90",
       "code":"TR",
       "dial_code":"+90"
    },
    {
       "name":"🇮🇳 +91",
       "code":"IN",
       "dial_code":"+91"
    },
    {
       "name":"🇵🇰 +92",
       "code":"PK",
       "dial_code":"+92"
    },
    {
       "name":"🇦🇫 +93",
       "code":"AF",
       "dial_code":"+93"
    },
    {
       "name":"🇱🇰 +94",
       "code":"LK",
       "dial_code":"+94"
    },
    {
       "name":"🇲🇲 +95",
       "code":"MM",
       "dial_code":"+95"
    },
    {
       "name":"🇮🇷 +98",
       "code":"IR",
       "dial_code":"+98"
    },
    {
       "name":"🇸🇸 +211",
       "code":"SS",
       "dial_code":"+211"
    },
    {
       "name":"🇲🇦 +212",
       "code":"MA",
       "dial_code":"+212"
    },
    {
       "name":"🇩🇿 +213",
       "code":"DZ",
       "dial_code":"+213"
    },
    {
       "name":"🇹🇳 +216",
       "code":"TN",
       "dial_code":"+216"
    },
    {
       "name":"🇱🇾 +218",
       "code":"LY",
       "dial_code":"+218"
    },
    {
       "name":"🇬🇲 +220",
       "code":"GM",
       "dial_code":"+220"
    },
    {
       "name":"🇸🇳 +221",
       "code":"SN",
       "dial_code":"+221"
    },
    {
       "name":"🇲🇷 +222",
       "code":"MR",
       "dial_code":"+222"
    },
    {
       "name":"🇲🇱 +223",
       "code":"ML",
       "dial_code":"+223"
    },
    {
       "name":"🇬🇳 +224",
       "code":"GN",
       "dial_code":"+224"
    },
    {
       "name":"🇨🇮 +225",
       "code":"CI",
       "dial_code":"+225"
    },
    {
       "name":"🇧🇫 +226",
       "code":"BF",
       "dial_code":"+226"
    },
    {
       "name":"🇳🇪 +227",
       "code":"NE",
       "dial_code":"+227"
    },
    {
       "name":"🇹🇬 +228",
       "code":"TG",
       "dial_code":"+228"
    },
    {
       "name":"🇧🇯 +229",
       "code":"BJ",
       "dial_code":"+229"
    },
    {
       "name":"🇲🇺 +230",
       "code":"MU",
       "dial_code":"+230"
    },
    {
       "name":"🇱🇷 +231",
       "code":"LR",
       "dial_code":"+231"
    },
    {
       "name":"🇸🇱 +232",
       "code":"SL",
       "dial_code":"+232"
    },
    {
       "name":"🇬🇭 +233",
       "code":"GH",
       "dial_code":"+233"
    },
    {
       "name":"🇳🇬 +234",
       "code":"NG",
       "dial_code":"+234"
    },
    {
       "name":"🇹🇩 +235",
       "code":"TD",
       "dial_code":"+235"
    },
    {
       "name":"🇨🇫 +236",
       "code":"CF",
       "dial_code":"+236"
    },
    {
       "name":"🇨🇲 +237",
       "code":"CM",
       "dial_code":"+237"
    },
    {
       "name":"🇨🇻 +238",
       "code":"CV",
       "dial_code":"+238"
    },
    {
       "name":"🇸🇹 +239",
       "code":"ST",
       "dial_code":"+239"
    },
    {
       "name":"🇬🇶 +240",
       "code":"GQ",
       "dial_code":"+240"
    },
    {
       "name":"🇬🇦 +241",
       "code":"GA",
       "dial_code":"+241"
    },
    {
       "name":"🇨🇬 +242",
       "code":"CG",
       "dial_code":"+242"
    },
    {
       "name":"🇨🇩 +243",
       "code":"CD",
       "dial_code":"+243"
    },
    {
       "name":"🇦🇴 +244",
       "code":"AO",
       "dial_code":"+244"
    },
    {
       "name":"🇬🇼 +245",
       "code":"GW",
       "dial_code":"+245"
    },
    {
       "name":"🇮🇴 +246",
       "code":"IO",
       "dial_code":"+246"
    },
    {
       "name":"🇸🇨 +248",
       "code":"SC",
       "dial_code":"+248"
    },
    {
       "name":"🇸🇩 +249",
       "code":"SD",
       "dial_code":"+249"
    },
    {
       "name":"🇷🇼 +250",
       "code":"RW",
       "dial_code":"+250"
    },
    {
       "name":"🇪🇹 +251",
       "code":"ET",
       "dial_code":"+251"
    },
    {
       "name":"🇸🇴 +252",
       "code":"SO",
       "dial_code":"+252"
    },
    {
       "name":"🇩🇯 +253",
       "code":"DJ",
       "dial_code":"+253"
    },
    {
       "name":"🇰🇪 +254",
       "code":"KE",
       "dial_code":"+254"
    },
    {
       "name":"🇹🇿 +255",
       "code":"TZ",
       "dial_code":"+255"
    },
    {
       "name":"🇺🇬 +256",
       "code":"UG",
       "dial_code":"+256"
    },
    {
       "name":"🇧🇮 +257",
       "code":"BI",
       "dial_code":"+257"
    },
    {
       "name":"🇲🇿 +258",
       "code":"MZ",
       "dial_code":"+258"
    },
    {
       "name":"🇿🇲 +260",
       "code":"ZM",
       "dial_code":"+260"
    },
    {
       "name":"🇲🇬 +261",
       "code":"MG",
       "dial_code":"+261"
    },
    {
       "name":"🇾🇹 +262",
       "code":"YT",
       "dial_code":"+262"
    },
    {
       "name":"🇷🇪 +262",
       "code":"RE",
       "dial_code":"+262"
    },
    {
       "name":"🇿🇼 +263",
       "code":"ZW",
       "dial_code":"+263"
    },
    {
       "name":"🇳🇦 +264",
       "code":"NA",
       "dial_code":"+264"
    },
    {
       "name":"🇲🇼 +265",
       "code":"MW",
       "dial_code":"+265"
    },
    {
       "name":"🇱🇸 +266",
       "code":"LS",
       "dial_code":"+266"
    },
    {
       "name":"🇧🇼 +267",
       "code":"BW",
       "dial_code":"+267"
    },
    {
       "name":"🇸🇿 +268",
       "code":"SZ",
       "dial_code":"+268"
    },
    {
       "name":"🇰🇲 +269",
       "code":"KM",
       "dial_code":"+269"
    },
    {
       "name":"🇸🇭 +290",
       "code":"SH",
       "dial_code":"+290"
    },
    {
       "name":"🇪🇷 +291",
       "code":"ER",
       "dial_code":"+291"
    },
    {
       "name":"🇦🇼 +297",
       "code":"AW",
       "dial_code":"+297"
    },
    {
       "name":"🇫🇴 +298",
       "code":"FO",
       "dial_code":"+298"
    },
    {
       "name":"🇬🇱 +299",
       "code":"GL",
       "dial_code":"+299"
    },
    {
       "name":"🇰🇾 +345",
       "code":"KY",
       "dial_code":"+345"
    },
    {
       "name":"🇬🇮 +350",
       "code":"GI",
       "dial_code":"+350"
    },
    {
       "name":"🇵🇹 +351",
       "code":"PT",
       "dial_code":"+351"
    },
    {
       "name":"🇱🇺 +352",
       "code":"LU",
       "dial_code":"+352"
    },
    {
       "name":"🇮🇪 +353",
       "code":"IE",
       "dial_code":"+353"
    },
    {
       "name":"🇮🇸 +354",
       "code":"IS",
       "dial_code":"+354"
    },
    {
       "name":"🇦🇱 +355",
       "code":"AL",
       "dial_code":"+355"
    },
    {
       "name":"🇲🇹 +356",
       "code":"MT",
       "dial_code":"+356"
    },
    {
       "name":"🇨🇾 +357",
       "code":"CY",
       "dial_code":"+357"
    },
    {
       "name":"🇦🇽 +358",
       "code":"AX",
       "dial_code":"+358"
    },
    {
       "name":"🇫🇮 +358",
       "code":"FI",
       "dial_code":"+358"
    },
    {
       "name":"🇧🇬 +359",
       "code":"BG",
       "dial_code":"+359"
    },
    {
       "name":"🇱🇹 +370",
       "code":"LT",
       "dial_code":"+370"
    },
    {
       "name":"🇱🇻 +371",
       "code":"LV",
       "dial_code":"+371"
    },
    {
       "name":"🇪🇪 +372",
       "code":"EE",
       "dial_code":"+372"
    },
    {
       "name":"🇲🇩 +373",
       "code":"MD",
       "dial_code":"+373"
    },
    {
       "name":"🇦🇲 +374",
       "code":"AM",
       "dial_code":"+374"
    },
    {
       "name":"🇧🇾 +375",
       "code":"BY",
       "dial_code":"+375"
    },
    {
       "name":"🇦🇩 +376",
       "code":"AD",
       "dial_code":"+376"
    },
    {
       "name":"🇲🇨 +377",
       "code":"MC",
       "dial_code":"+377"
    },
    {
       "name":"🇸🇲 +378",
       "code":"SM",
       "dial_code":"+378"
    },
    {
       "name":"🇻🇦 +379",
       "code":"VA",
       "dial_code":"+379"
    },
    {
       "name":"🇺🇦 +380",
       "code":"UA",
       "dial_code":"+380"
    },
    {
       "name":"🇷🇸 +381",
       "code":"RS",
       "dial_code":"+381"
    },
    {
       "name":"🇲🇪 +382",
       "code":"ME",
       "dial_code":"+382"
    },
    {
       "name":"🇭🇷 +385",
       "code":"HR",
       "dial_code":"+385"
    },
    {
       "name":"🇸🇮 +386",
       "code":"SI",
       "dial_code":"+386"
    },
    {
       "name":"🇧🇦 +387",
       "code":"BA",
       "dial_code":"+387"
    },
    {
       "name":"🇲🇰 +389",
       "code":"MK",
       "dial_code":"+389"
    },
    {
       "name":"🇨🇿 +420",
       "code":"CZ",
       "dial_code":"+420"
    },
    {
       "name":"🇸🇰 +421",
       "code":"SK",
       "dial_code":"+421"
    },
    {
       "name":"🇱🇮 +423",
       "code":"LI",
       "dial_code":"+423"
    },
    {
       "name":"🇫🇰 +500",
       "code":"FK",
       "dial_code":"+500"
    },
    {
       "name":"🇬🇸 +500",
       "code":"GS",
       "dial_code":"+500"
    },
    {
       "name":"🇧🇿 +501",
       "code":"BZ",
       "dial_code":"+501"
    },
    {
       "name":"🇬🇹 +502",
       "code":"GT",
       "dial_code":"+502"
    },
    {
       "name":"🇸🇻 +503",
       "code":"SV",
       "dial_code":"+503"
    },
    {
       "name":"🇭🇳 +504",
       "code":"HN",
       "dial_code":"+504"
    },
    {
       "name":"🇳🇮 +505",
       "code":"NI",
       "dial_code":"+505"
    },
    {
       "name":"🇨🇷 +506",
       "code":"CR",
       "dial_code":"+506"
    },
    {
       "name":"🇵🇦 +507",
       "code":"PA",
       "dial_code":"+507"
    },
    {
       "name":"🇵🇲 +508",
       "code":"PM",
       "dial_code":"+508"
    },
    {
       "name":"🇭🇹 +509",
       "code":"HT",
       "dial_code":"+509"
    },
    {
       "name":"🇬🇵 +590",
       "code":"GP",
       "dial_code":"+590"
    },
    {
       "name":"🇧🇱 +590",
       "code":"BL",
       "dial_code":"+590"
    },
    {
       "name":"🇲🇫 +590",
       "code":"MF",
       "dial_code":"+590"
    },
    {
       "name":"🇧🇴 +591",
       "code":"BO",
       "dial_code":"+591"
    },
    {
       "name":"🇪🇨 +593",
       "code":"EC",
       "dial_code":"+593"
    },
    {
       "name":"🇬🇫 +594",
       "code":"GF",
       "dial_code":"+594"
    },
    {
       "name":"🇬🇾 +595",
       "code":"GY",
       "dial_code":"+595"
    },
    {
       "name":"🇵🇾 +595",
       "code":"PY",
       "dial_code":"+595"
    },
    {
       "name":"🇲🇶 +596",
       "code":"MQ",
       "dial_code":"+596"
    },
    {
       "name":"🇸🇷 +597",
       "code":"SR",
       "dial_code":"+597"
    },
    {
       "name":"🇺🇾 +598",
       "code":"UY",
       "dial_code":"+598"
    },
    {
       "name":"🇦🇳 +599",
       "code":"AN",
       "dial_code":"+599"
    },
    {
       "name":"🇹🇱 +670",
       "code":"TL",
       "dial_code":"+670"
    },
    {
       "name":"🇦🇶 +672",
       "code":"AQ",
       "dial_code":"+672"
    },
    {
       "name":"🇳🇫 +672",
       "code":"NF",
       "dial_code":"+672"
    },
    {
       "name":"🇧🇳 +673",
       "code":"BN",
       "dial_code":"+673"
    },
    {
       "name":"🇳🇷 +674",
       "code":"NR",
       "dial_code":"+674"
    },
    {
       "name":"🇵🇬 +675",
       "code":"PG",
       "dial_code":"+675"
    },
    {
       "name":"🇹🇴 +676",
       "code":"TO",
       "dial_code":"+676"
    },
    {
       "name":"🇸🇧 +677",
       "code":"SB",
       "dial_code":"+677"
    },
    {
       "name":"🇻🇺 +678",
       "code":"VU",
       "dial_code":"+678"
    },
    {
       "name":"🇫🇯 +679",
       "code":"FJ",
       "dial_code":"+679"
    },
    {
       "name":"🇵🇼 +680",
       "code":"PW",
       "dial_code":"+680"
    },
    {
       "name":"🇼🇫 +681",
       "code":"WF",
       "dial_code":"+681"
    },
    {
       "name":"🇨🇰 +682",
       "code":"CK",
       "dial_code":"+682"
    },
    {
       "name":"🇳🇺 +683",
       "code":"NU",
       "dial_code":"+683"
    },
    {
       "name":"🇼🇸 +685",
       "code":"WS",
       "dial_code":"+685"
    },
    {
       "name":"🇰🇮 +686",
       "code":"KI",
       "dial_code":"+686"
    },
    {
       "name":"🇳🇨 +687",
       "code":"NC",
       "dial_code":"+687"
    },
    {
       "name":"🇹🇻 +688",
       "code":"TV",
       "dial_code":"+688"
    },
    {
       "name":"🇵🇫 +689",
       "code":"PF",
       "dial_code":"+689"
    },
    {
       "name":"🇹🇰 +690",
       "code":"TK",
       "dial_code":"+690"
    },
    {
       "name":"🇫🇲 +691",
       "code":"FM",
       "dial_code":"+691"
    },
    {
       "name":"🇲🇭 +692",
       "code":"MH",
       "dial_code":"+692"
    },
    {
       "name":"🇰🇵 +850",
       "code":"KP",
       "dial_code":"+850"
    },
    {
       "name":"🇭🇰 +852",
       "code":"HK",
       "dial_code":"+852"
    },
    {
       "name":"🇲🇴 +853",
       "code":"MO",
       "dial_code":"+853"
    },
    {
       "name":"🇰🇭 +855",
       "code":"KH",
       "dial_code":"+855"
    },
    {
       "name":"🇱🇦 +856",
       "code":"LA",
       "dial_code":"+856"
    },
    {
       "name":"🇵🇳 +872",
       "code":"PN",
       "dial_code":"+872"
    },
    {
       "name":"🇧🇩 +880",
       "code":"BD",
       "dial_code":"+880"
    },
    {
       "name":"🇹🇼 +886",
       "code":"TW",
       "dial_code":"+886"
    },
    {
       "name":"🇲🇻 +960",
       "code":"MV",
       "dial_code":"+960"
    },
    {
       "name":"🇱🇧 +961",
       "code":"LB",
       "dial_code":"+961"
    },
    {
       "name":"🇯🇴 +962",
       "code":"JO",
       "dial_code":"+962"
    },
    {
       "name":"🇸🇾 +963",
       "code":"SY",
       "dial_code":"+963"
    },
    {
       "name":"🇮🇶 +964",
       "code":"IQ",
       "dial_code":"+964"
    },
    {
       "name":"🇰🇼 +965",
       "code":"KW",
       "dial_code":"+965"
    },
    {
       "name":"🇸🇦 +966",
       "code":"SA",
       "dial_code":"+966"
    },
    {
       "name":"🇾🇪 +967",
       "code":"YE",
       "dial_code":"+967"
    },
    {
       "name":"🇴🇲 +968",
       "code":"OM",
       "dial_code":"+968"
    },
    {
       "name":"🇵🇸 +970",
       "code":"PS",
       "dial_code":"+970"
    },
    {
       "name":"🇦🇪 +971",
       "code":"AE",
       "dial_code":"+971"
    },
    {
       "name":"🇮🇱 +972",
       "code":"IL",
       "dial_code":"+972"
    },
    {
       "name":"🇧🇭 +973",
       "code":"BH",
       "dial_code":"+973"
    },
    {
       "name":"🇶🇦 +974",
       "code":"QA",
       "dial_code":"+974"
    },
    {
       "name":"🇧🇹 +975",
       "code":"BT",
       "dial_code":"+975"
    },
    {
       "name":"🇲🇳 +976",
       "code":"MN",
       "dial_code":"+976"
    },
    {
       "name":"🇳🇵 +977",
       "code":"NP",
       "dial_code":"+977"
    },
    {
       "name":"🇹🇯 +992",
       "code":"TJ",
       "dial_code":"+992"
    },
    {
       "name":"🇹🇲 +993",
       "code":"TM",
       "dial_code":"+993"
    },
    {
       "name":"🇦🇿 +994",
       "code":"AZ",
       "dial_code":"+994"
    },
    {
       "name":"🇬🇪 +995",
       "code":"GE",
       "dial_code":"+995"
    },
    {
       "name":"🇰🇬 +996",
       "code":"KG",
       "dial_code":"+996"
    },
    {
       "name":"🇺🇿 +998",
       "code":"UZ",
       "dial_code":"+998"
    },
    {
       "name":"🇧🇸 +1242",
       "code":"BS",
       "dial_code":"+1242"
    },
    {
       "name":"🇧🇧 +1246",
       "code":"BB",
       "dial_code":"+1246"
    },
    {
       "name":"🇦🇮 +1264",
       "code":"AI",
       "dial_code":"+1264"
    },
    {
       "name":"🇦🇬 +1268",
       "code":"AG",
       "dial_code":"+1268"
    },
    {
       "name":"🇻🇬 +1284",
       "code":"VG",
       "dial_code":"+1284"
    },
    {
       "name":"🇻🇮 +1340",
       "code":"VI",
       "dial_code":"+1340"
    },
    {
       "name":"🇧🇲 +1441",
       "code":"BM",
       "dial_code":"+1441"
    },
    {
       "name":"🇬🇩 +1473",
       "code":"GD",
       "dial_code":"+1473"
    },
    {
       "name":"🇹🇨 +1649",
       "code":"TC",
       "dial_code":"+1649"
    },
    {
       "name":"🇲🇸 +1664",
       "code":"MS",
       "dial_code":"+1664"
    },
    {
       "name":"🇲🇵 +1670",
       "code":"MP",
       "dial_code":"+1670"
    },
    {
       "name":"🇬🇺 +1671",
       "code":"GU",
       "dial_code":"+1671"
    },
    {
       "name":"🇦🇸 +1684",
       "code":"AS",
       "dial_code":"+1684"
    },
    {
       "name":"🇱🇨 +1758",
       "code":"LC",
       "dial_code":"+1758"
    },
    {
       "name":"🇩🇲 +1767",
       "code":"DM",
       "dial_code":"+1767"
    },
    {
       "name":"🇻🇨 +1784",
       "code":"VC",
       "dial_code":"+1784"
    },
    {
       "name":"🇩🇴 +1849",
       "code":"DO",
       "dial_code":"+1849"
    },
    {
       "name":"🇹🇹 +1868",
       "code":"TT",
       "dial_code":"+1868"
    },
    {
       "name":"🇰🇳 +1869",
       "code":"KN",
       "dial_code":"+1869"
    },
    {
       "name":"🇯🇲 +1876",
       "code":"JM",
       "dial_code":"+1876"
    },
    {
       "name":"🇵🇷 +1939",
       "code":"PR",
       "dial_code":"+1939"
    }
]